const ACCESS_TOKEN = "access_token";
const REFRESH_TOKEN = "refresh_token";
const USER = "user";
const ACCOUNT = "account";
const APPS = "apps";
const SELECTED_APP = "selected_app";
const ONBOARDING_APPS = "onboarding_apps";
const OPENED_EMAIL_VERIFICATION_DIALOG = "opened email verification dialog";

export function getUserCredentials() {
    return {
        accessToken: getAccessToken(),
        refreshToken: getRefreshToken(),
        user: getUser(),
        account: getAccount(),
        apps: getApps(),
        selectedApp: getSelectedApp()
    };
}

export function getAccessToken() {
    return localStorage.getItem(ACCESS_TOKEN);
}

export function storeAccessToken(accessToken) {
    return localStorage.setItem(ACCESS_TOKEN, accessToken);
}

export function storeRefreshToken(refreshToken) {
    return localStorage.setItem(REFRESH_TOKEN, refreshToken);
}

export function setOpenedEmailVerificationDialog(flag) {
    return localStorage.setItem(OPENED_EMAIL_VERIFICATION_DIALOG, flag);
}

export function getOpenedEmailVerificationDialog() {
    return localStorage.getItem(OPENED_EMAIL_VERIFICATION_DIALOG) == "true";
}

export function storeUser(user) {
    return localStorage.setItem(USER, JSON.stringify(user));
}

export function getUser() {
    let user = localStorage.getItem(USER);
    if (user) {
        return JSON.parse(user);
    }
    return null;
}

export function storeAccount(account) {
    return localStorage.setItem(ACCOUNT, JSON.stringify(account));
}

export function getAccount() {
    let account = localStorage.getItem(ACCOUNT);
    if (account) {
        try {
            return JSON.parse(account);
        } catch (e) {
            console.log(e);
        }
    }
    return null;
}

export function getRefreshToken() {
    return localStorage.getItem(REFRESH_TOKEN);
}

export function storeUserCredentials({ accessToken, refreshToken, account = null, user = null }) {
    localStorage.setItem(ACCESS_TOKEN, accessToken);
    localStorage.setItem(REFRESH_TOKEN, refreshToken);
    localStorage.setItem(USER, JSON.stringify(user));
    localStorage.setItem(ACCOUNT, JSON.stringify(account));
}

export function clearUserCredentials() {
    localStorage.setItem(ACCESS_TOKEN, null);
    localStorage.setItem(REFRESH_TOKEN, null);
    localStorage.setItem(USER, null);
    localStorage.setItem(ACCOUNT, null);
    localStorage.setItem(OPENED_EMAIL_VERIFICATION_DIALOG, null);
    localStorage.setItem(APPS, null);
    localStorage.setItem(SELECTED_APP, null);
}

export function getApps() {
    let apps = localStorage.getItem(APPS);
    if (apps) {
        return JSON.parse(apps);
    }
    return null;
}

export function storeApps(apps) {
    return localStorage.setItem(APPS, JSON.stringify(apps));
}

export function getSelectedApp() {
    let selectedAppId = localStorage.getItem(SELECTED_APP);
    if (selectedAppId) {
        let apps = getApps();
        if (apps) {
            for (let i = 0; i < apps.length; i++) {
                if (apps[i].app_sid == selectedAppId) {
                    return apps[i];
                }
            }
        }
    }
    return null;
}

export function storeSelectedAppId(selectedAppId) {
    return localStorage.setItem(SELECTED_APP, selectedAppId);
}

export function clearApps() {
    return localStorage.setItem(APPS, null);
}

export function getOnboardingDisabledApps() {
    let apps = localStorage.getItem(ONBOARDING_APPS);
    if (apps) {
        return JSON.parse(apps);
    }
    return null;
}

export function setOnboardingDisabledApps(appId) {
    let apps = localStorage.getItem(ONBOARDING_APPS);
    let appsArr = [];
    if (apps) {
        appsArr = JSON.parse(apps);
        let found = appsArr.find(it => it === appId);
        if (!found) {
            appsArr.push(appId);
        }
    } else {
        appsArr = [appId];
    }

    localStorage.setItem(ONBOARDING_APPS, JSON.stringify(appsArr));

    return null;
}
