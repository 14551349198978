import { combineReducers } from "redux";
//core
import userReducer from "./userReducer";
import eventsReducer from "./eventsReducer";
import appUserReducer from "./appUserReducer";
import messageEventReducer from "./messageEventReducer";
import loadingReducer from "./loadingReducer";
import membersReducer from "./membersReducer";

const rootReducer = combineReducers({
    //account: accountReducer
    account: userReducer,
    events: eventsReducer,
    appUsers: appUserReducer,
    messageEvents: messageEventReducer,
    loading: loadingReducer,
    members: membersReducer
});

export default rootReducer;
