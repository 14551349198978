import React from "react";
import PropTypes from "prop-types";
import { Grid, makeStyles, Typography, useTheme } from "@material-ui/core";
import { useSelector } from "react-redux";
const useStyles = makeStyles(theme => ({
    hr: {
        height: "3px",
        margin: 0,
        padding: 0
    },
    modebannercontainer: {
        backgroundColor: "#F4F6F8"
    },
    modebanner: {
        borderBottomLeftRadius: "10px",
        borderBottomRightRadius: "10px",
        height: "20px",
        paddingBottom: "5px"
    },
    modebannertext: {
        color: "white",
        fontSize: theme.typography.pxToRem(12),
        fontWeight: theme.typography.fontWeightMedium,
        textAlign: "center"
    }
}));

function AppModeBanner() {
    const classes = useStyles();
    const theme = useTheme();
    const { selectedApp, user } = useSelector(state => state.account);
    const isDev = selectedApp.type === "development";
    return (
        <>
            {user["verified"] && isDev && (
                <>
                    <hr color={theme.palette.warning.main} className={classes.hr} />
                    <Grid
                        className={classes.modebannercontainer}
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Grid item xs={5}></Grid>

                        <Grid
                            item
                            className={classes.modebanner}
                            xs={2}
                            style={{
                                backgroundColor: theme.palette.warning.main
                            }}
                        >
                            <Typography className={classes.modebannertext}>Test</Typography>
                        </Grid>
                        <Grid item xs={5}></Grid>
                    </Grid>
                </>
            )}
        </>
    );
}

AppModeBanner.propTypes = {
    className: PropTypes.string
};

export default AppModeBanner;
