export const GET_MEMBERS_REQUEST = "@members/get-members-request";
export const GET_MEMBERS_SUCCESS = "@members/get-members-success";
export const GET_MEMBERS_FAILURE = "@members/get-members-failure";

export function getMembersRequest() {
    return {
        type: GET_MEMBERS_REQUEST
    };
}

export function getMembersSuccess(members) {
    return {
        type: GET_MEMBERS_SUCCESS,
        payload: {
            members
        }
    };
}

export function getMembersFailure() {
    return {
        type: GET_MEMBERS_FAILURE
    };
}
