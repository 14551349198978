/* eslint-disable react/no-array-index-key */
import React, { lazy, Suspense, Fragment } from "react";
import { Switch, Redirect, Route } from "react-router-dom";
import DashboardLayout from "src/views/DashboardLayout";
import LoadingScreen from "src/components/LoadingScreen";
import AuthGuard from "src/components/AuthGuard";
import GuestGuard from "src/components/GuestGuard";

export const FORGOT_PWD_PATH = "/password/forgot";
export const CHANGE_PWD_PATH = "/password/change";
export const LOGIN_PATH = "/login";
export const REGISTER_PATH = "/register";
export const JOIN_ORGANIZATION_PATH = "/account/join";
export const VERIFY_EMAIL_PATH = "/account/verify-email";
export const GET_STARTED_PATH = "/get-started";
export const JOIN_ACCOUNT_PATH = "/account/:accountName/join";
export const PENDING_INVITE_PATH = "/account/pending-invite";

export const GET_JOIN_ACCOUNT_PATH = accountName => {
    return `/account/${accountName}/join`;
};

const routesConfig = [
    {
        exact: true,
        path: "/",
        component: () => <Redirect to="/app" />
    },
    {
        exact: true,
        path: "/404",
        component: lazy(() => import("src/views/404/Error404View"))
    },
    {
        exact: true,
        guard: GuestGuard,
        path: "/login",
        component: lazy(() => import("src/views/auth/LoginView"))
    },
    {
        exact: true,
        path: "/login-unprotected",
        component: lazy(() => import("src/views/auth/LoginView"))
    },
    {
        exact: true,
        guard: GuestGuard,
        path: "/register",
        component: lazy(() => import("src/views/auth/RegisterView"))
    },
    {
        exact: true,
        guard: GuestGuard,
        path: "/account/join",
        component: lazy(() => import("src/views/auth/JoinExistingDomain"))
    },
    {
        exact: true,
        guard: GuestGuard,
        path: "/account/verify-email",
        component: lazy(() => import("src/views/auth/VerifyEmailView"))
    },
    {
        exact: true,
        path: "/get-started",
        component: lazy(() => import("src/views/auth/GetStartedView"))
    },
    {
        exact: true,
        path: "/password/forgot",
        component: lazy(() => import("src/views/auth/ForgotPasswordView"))
    },
    {
        exact: true,
        path: "/password/change",
        component: lazy(() => import("src/views/auth/ResetPasswordView"))
    },
    {
        exact: true,
        path: "/verify-email",
        component: lazy(() => import("src/views/auth/VerifyEmailLink/VerifyEmailRedirect"))
    },
    {
        exact: true,
        path: "/register-unprotected",
        component: lazy(() => import("src/views/auth/RegisterView"))
    },
    {
        exact: true,
        path: "/invite",
        component: lazy(() => import("src/views/auth/JoinAccountView/JoinFromInviteEmail"))
    },
    {
        exact: true,
        path: "/account/pending-invite",
        component: lazy(() => import("src/views/auth/PendingInviteView"))
    },
    {
        exact: true,
        guard: GuestGuard,
        path: "/account/:accountName/join",
        component: lazy(() => import("src/views/auth/JoinAccountView/JoinExistingAccount"))
    },
    {
        exact: true,
        path: "/redirect/razorpay",
        component: lazy(() =>
            import("src/views/Settings/AppsView/Extensions/Razorpay/RazorpayRedirect")
        )
    },
    {
        path: "/app",
        guard: AuthGuard,
        layout: DashboardLayout,
        routes: [
            {
                exact: true,
                path: "/app",
                component: () => <Redirect to="/app/management/templates" />
            },
            {
                exact: true,
                path: "/app/get-started",
                component: lazy(() => import("src/views/GetStarted"))
            },
            {
                exact: true,
                path: "/app/dashboard/metrics",
                component: lazy(() => import("src/views/Metrics"))
            },
            {
                exact: true,
                path: "/app/dashboard/logs",
                component: lazy(() => import("src/views/Logs"))
            },
            {
                exact: true,
                path: "/app/management/settings",
                component: lazy(() => import("src/views/Settings"))
            },
            {
                exact: true,
                path: "/app/management/integrations",
                component: lazy(() => import("src/views/Integrations"))
            },
            {
                exact: true,
                path: "/app/management/events",
                component: lazy(() => import("src/views/events/EventListView"))
            },
            {
                exact: true,
                path: "/app/management/templates",
                component: lazy(() => import("src/views/templates/TemplateListView"))
            },
            {
                exact: true,
                path: "/app/management/users",
                component: lazy(() => import("src/views/users"))
            },
            {
                component: () => <Redirect to="/404" />
            }
        ]
    },
    {
        component: () => <Redirect to="/404" />
    }
];

const renderRoutes = routes =>
    routes ? (
        <Suspense fallback={<LoadingScreen />}>
            <Switch>
                {routes.map((route, i) => {
                    const Guard = route.guard || Fragment;
                    const Layout = route.layout || Fragment;
                    const Component = route.component;

                    return (
                        <Route
                            key={i}
                            path={route.path}
                            exact={route.exact}
                            render={props => (
                                <Guard>
                                    <Layout>
                                        {route.routes ? (
                                            renderRoutes(route.routes)
                                        ) : (
                                            <Component {...props} />
                                        )}
                                    </Layout>
                                </Guard>
                            )}
                        />
                    );
                })}
            </Switch>
        </Suspense>
    ) : null;

function Routes() {
    return renderRoutes(routesConfig);
}

export default Routes;
