import * as moment from "moment";
import _ from "lodash";
import React from "react";
import { Slack as SlackIcon, Triangle as TriangleIcon, Bell } from "react-feather";
import MailOutlinedIcon from "@material-ui/icons/MailOutlined";
import NotificationsNoneOutlinedIcon from "@material-ui/icons/NotificationsNoneOutlined";
import SmsOutlinedIcon from "@material-ui/icons/SmsOutlined";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import PhoneOutlinedIcon from "@material-ui/icons/PhoneOutlined";
import CheckRoundedIcon from "@material-ui/icons/CheckRounded";
import HourglassFullRoundedIcon from "@material-ui/icons/HourglassFullRounded";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import ForumOutlinedIcon from "@material-ui/icons/ForumOutlined";
import CallMadeIcon from "@material-ui/icons/CallMade";
import lz from "lzutf8";
import { useTheme, alpha, colors } from "@material-ui/core";
import { PermDeviceInformationOutlined, Telegram } from "@material-ui/icons";

export function titleCase(str) {
    if (str === "sms" || str === "SMS") {
        return "SMS";
    }

    if (str === "whatsapp" || str === "WHATSAPP") {
        return "WhatsApp";
    }

    if (str == null) {
        return "";
    }

    return str.toLowerCase().replace(/\b(\w)/g, s => s.toUpperCase());
}

export function isBlank(str) {
    return !str || str.length == 0;
}

export function isNotBlank(str) {
    return !isBlank(str);
}

export const createLoadingSelector = actions => state => {
    // returns true only when all actions is not loading
    return _(actions).some(action => _.get(state, `loading.${action}`));
};

export function tryParseJSON(jsonString) {
    try {
        var o = JSON.parse(jsonString);

        // Handle non-exception-throwing cases:
        // Neither JSON.parse(false) or JSON.parse(1234) throw errors, hence the type-checking,
        // but... JSON.parse(null) returns null, and typeof null === "object",
        // so we must check for that, too. Thankfully, null is falsey, so this suffices:
        if (o && typeof o === "object") {
            return o;
        }
    } catch (e) {}

    return false;
}

export function getChannelValueFromLabel(label) {
    for (let i = 0; i < channelsAvailable.length; i++) {
        if (channelsAvailable[i].label === label) {
            return channelsAvailable[i].value;
        }
    }
    return null;
}

export function getChannelLabelFromValue(value) {
    for (let i = 0; i < channelsAvailable.length; i++) {
        if (channelsAvailable[i].value === value) {
            return channelsAvailable[i].label;
        }
    }
    return null;
}

export const channelsAvailable = [
    {
        value: "push",
        label: "Push"
    },
    {
        value: "sms",
        label: "SMS"
    },
    {
        value: "email",
        label: "Email"
    },
    {
        value: "whatsapp",
        label: "WhatsApp"
    },
    {
        value: "slack",
        label: "Slack"
    },
    {
        value: "voice",
        label: "Voice"
    },
    {
        value: "in_app",
        label: "In-App"
    },
    {
        value: "webhook",
        label: "Webhook"
    },
    {
        value: "telegram",
        label: "Telegram"
    }
];

export const displayChannels = [
    {
        value: ["sms"],
        group: "SMS",
        label: "SMS"
    },
    {
        value: ["push"],
        group: "Push",
        label: "Push"
    },
    {
        value: ["email"],
        group: "Email",
        label: "Email"
    },
    {
        value: ["whatsapp"],
        group: "WhatsApp",
        label: "WhatsApp"
    },
    {
        value: ["voice"],
        group: "Voice",
        label: "Voice"
    },
    {
        value: ["in_app"],
        group: "In-App",
        label: "In-App"
    },
    {
        value: ["slack", "webhook", "telegram"],
        group: "Others",
        label: "Others"
    }
];

export const channelsDeliveryStatus = [
    {
        channel: "push",
        user: "token",
        fallbackStatus: ["not_delivered", "failed"]
    },
    {
        channel: "sms",
        user: "mobile no.",
        fallbackStatus: ["not_delivered", "failed"]
    },
    {
        channel: "email",
        user: "user email",
        fallbackStatus: ["not_delivered", "failed", "not_read"]
    },
    {
        channel: "whatsapp",
        user: "mobile no.",
        fallbackStatus: ["not_delivered", "failed"]
    },
    {
        channel: "slack",
        user: "slack token",
        fallbackStatus: ["failed"]
    },
    {
        channel: "voice",
        user: "mobile no.",
        fallbackStatus: ["not_delivered", "failed"]
    },
    {
        channel: "in_app",
        user: "user id",
        fallbackStatus: ["not_delivered", "failed"]
    },
    {
        channel: "telegram",
        user: "chat id",
        fallbackStatus: ["failed"]
    },
    {
        channel: "webhook",
        user: null,
        fallbackStatus: ["failed"]
    }
];

export const userChannelsMap = [
    {
        channel: "push",
        userChannel: ["devices"],
        displayChannel: "FCM Token"
    },
    {
        channel: "push",
        userChannel: ["onesignal_external_id"],
        displayChannel: "Onesignal Token"
    },
    {
        channel: "sms",
        userChannel: ["mobile"],
        displayChannel: "Mobile"
    },
    {
        channel: "email",
        userChannel: ["email"],
        displayChannel: "Email"
    },
    {
        channel: "whatsapp",
        userChannel: ["whatsapp_mobile", "mobile"],
        displayChannel: "Mobile"
    },
    {
        channel: "slack",
        userChannel: ["slack"],
        displayChannel: "Slack Token"
    },
    {
        channel: "voice",
        userChannel: ["mobile"],
        displayChannel: "Mobile"
    },
    {
        channel: "in_app",
        userChannel: ["user_id"],
        displayChannel: "User ID"
    },
    {
        channel: "webhook",
        userChannel: ["webhook"],
        displayChannel: "Webhook"
    },
    {
        channel: "telegram",
        userChannel: ["telegram"],
        displayChannel: "telegram"
    }
];

export const deliveryStatusObject = {
    VOICE: ["answered", "busy", "no_answer", "canceled"],
    WHATSAPP: ["read"],
    PUSH: ["clicked", "dismissed"],
    SMS: [],
    WEBHOOK: [],
    SLACK: [],
    EMAIL: ["opened", "clicked"],
    TELEGRAM: [],
    IN_APP: ["read"]
};

export const statusAfterDelivered = [
    "busy",
    "answered",
    "no_answer",
    "canceled",
    "read",
    "clicked",
    "dismissed",
    "opened"
];

export function getDateString(dateValue) {
    var date = new Date(parseInt(dateValue, 10));
    return moment(date).format("DD-MM-YYYY hh:mm a");
}

export function getDateTimeString(dateValue) {
    var date = new Date(parseInt(dateValue, 10));
    return moment(date).format("DD-MM-YYYY hh:mm:ss.SSS a");
}

export function getTimeString(dateValue) {
    var date = new Date(parseInt(dateValue, 10));
    return moment(date).format("hh:mm:ss.SSS a");
}

String.prototype.format = function() {
    return [...arguments].reduce((p, c) => p.replace(/%s/, c), this);
};

export function getProviderImage(providerKey) {
    if (!providerImageMap[providerKey]) {
        return providerImageMap["UNKNOWN"];
    }

    return providerImageMap[providerKey];
}

export function getProviderCover(providerKey) {
    if (!providerCoverMap[providerKey]) {
        return null;
    }

    return providerCoverMap[providerKey];
}

export const providerImageMap = {
    FCM: "/static/images/providers/firebase.webp",
    TWO_FACTOR: "/static/images/providers/2factor.webp",
    TWILIO: "/static/images/providers/twilio.webp",
    MSG91: "/static/images/providers/msg91.webp",
    GUPSHUP: "/static/images/providers/gupshup.webp",
    MESSAGE_BIRD: "/static/images/providers/messagebird.webp",
    AWS_SES: "/static/images/providers/ses.webp",
    VALUE_FIRST: "/static/images/providers/vfirst.webp",
    SENDGRID: "/static/images/providers/sendgrid.png",
    SMOOCH: "/static/images/providers/smooch.png",
    KALEYRA: "/static/images/providers/kaleyra.png",
    UNIFIED_ANDROID_PUSH: "/static/images/providers/unified.png",
    PLIVO: "/static/images/providers/plivo.png",
    EXOTEL: "/static/images/providers/exotel.png",
    OTHERS: "/static/images/providers/others.png",
    SLACK: "/static/images/providers/slack.png",
    TELEGRAM: "/static/images/providers/placeholder.webp",
    WEBHOOK: "/static/images/providers/webhooks.png",
    BULK_SMS_OFFERS: "/static/images/providers/bso.png",
    ONE_SIGNAL: "/static/images/providers/onesignal.png",
    TEXT_LOCAL: "/static/images/providers/textlocal.png",
    RAVEN: "/static/raven.png",
    MAILGUN: "/static/images/providers/mailgun.png",
    POSTMARK: "/static/images/providers/postmark.png",
    ROUTE_MOBILE: "/static/images/providers/route_mobile.jpg",
    ACL: "/static/images/providers/acl.jpg",
    TELEGRAM: "/static/images/providers/telegram.png",
    UNKNOWN: "/static/images/providers/placeholder.webp"
};

export const providerCoverMap = {
    FCM: "/static/images/providers/cover/firebase.png",
    TWO_FACTOR: "/static/images/providers/cover/2factor.png",
    TWILIO: "/static/images/providers/cover/twilio.png",
    MSG91: "/static/images/providers/cover/msg91.png",
    GUPSHUP: "/static/images/providers/cover/gupshup.png",
    MESSAGE_BIRD: "/static/images/providers/cover/messagebird.png",
    VALUE_FIRST: "/static/images/providers/cover/vfirst.png",
    AWS_SES: "/static/images/providers/cover/ses.png",
    SENDGRID: "/static/images/providers/cover/sendgrid.png",
    SMOOCH: "/static/images/providers/cover/smooch.png",
    KALEYRA: "/static/images/providers/cover/kaleyra.png",
    UNIFIED_ANDROID_PUSH: "/static/images/providers/cover/unified.png",
    PLIVO: "/static/images/providers/cover/plivo.png",
    EXOTEL: "/static/images/providers/cover/exotel.png",
    SLACK: "/static/images/providers/cover/slack.png",
    TELEGRAM: "/static/images/providers/cover/placeholder.png",
    WEBHOOK: "/static/images/providers/cover/webhooks.png",
    OTHERS: "/static/images/providers/cover/others.png",
    ONE_SIGNAL: "/static/images/providers/cover/onesignal.png",
    TEXT_LOCAL: "/static/images/providers/cover/textlocal.png",
    RAVEN: "/static/images/providers/cover/raven.png",
    MAILGUN: "/static/images/providers/cover/mailgun.png",
    POSTMARK: "/static/images/providers/cover/postmark.png",
    ROUTE_MOBILE: "/static/images/providers/cover/route_mobile.png",
    ACL: "/static/images/providers/cover/acl.png",
    TELEGRAM: "/static/images/providers/cover/telegram.png",
    UNKNOWN: "/static/images/providers/cover/placeholder.png"
};

export function getChannelImage(channel, children) {
    if (channel.toLowerCase() === "push") {
        return <NotificationsNoneOutlinedIcon {...children} />;
    }

    if (channel.toLowerCase() === "sms") {
        return <SmsOutlinedIcon {...children} />;
    }

    if (channel.toLowerCase() === "email") {
        return <MailOutlinedIcon {...children} />;
    }

    if (channel.toLowerCase() === "whatsapp") {
        return <WhatsAppIcon {...children} />;
    }

    if (channel.toLowerCase() === "voice") {
        return <PhoneOutlinedIcon {...children} />;
    }

    if (channel.toLowerCase() === "slack") {
        return <SlackIcon {...children} />;
    }

    if (channel.toLowerCase() === "telegram") {
        return <Telegram {...children} />;
    }

    if (channel.toLowerCase() === "in_app") {
        return <PermDeviceInformationOutlined {...children} />;
    }

    if (channel.toLowerCase() === "webhook") {
        return <CallMadeIcon {...children} />;
    }
}

export function getChannelGroupImage(channel, children) {
    if (channel.toLowerCase() === "push") {
        return <NotificationsNoneOutlinedIcon {...children} />;
    }

    if (channel.toLowerCase() === "sms") {
        return <SmsOutlinedIcon {...children} />;
    }

    if (channel.toLowerCase() === "email") {
        return <MailOutlinedIcon {...children} />;
    }

    if (channel.toLowerCase() === "whatsapp") {
        return <WhatsAppIcon {...children} />;
    }

    if (channel.toLowerCase() === "voice") {
        return <PhoneOutlinedIcon {...children} />;
    }

    if (channel.toLowerCase() === "in-app") {
        return <PermDeviceInformationOutlined {...children} />;
    }

    if (channel.toLowerCase() === "others") {
        return <ForumOutlinedIcon {...children} />;
    }
}

export function getVowel(word) {
    if (["a", "e", "i", "o", "u"].indexOf(word[0].toLowerCase()) > -1) {
        return "an";
    } else if ("sms" === word.toLowerCase()) {
        return "an";
    } else {
        return "a";
    }
}

export const nullSafeGet = (m, key) => {
    if (m[key] == null) {
    }
};

export const messageStatusColor = {
    SENT: "success",
    DELIVERED: "success",
    PROCESSING: "warning",
    FAILED: "error",
    ERROR: "error"
};

export const messageStatusColorArray = {
    SENT: ["#1976d2", "#e3f2fd"],
    DELIVERED: ["#689f38", "#f1f8e9"],
    PROCESSING: ["#f57c00", "#fff3e0"],
    FAILED: ["#d32f2f", "#ffebee"],
    ERROR: ["#f57c00", "#fff3e0"],
    READ: ["#303f9f", "#e8eaf6"],
    CLICKED: ["#303f9f", "#e8eaf6"],
    DISMISSED: ["#303f9f", "#e8eaf6"],
    OPENED: ["#303f9f", "#e8eaf6"],
    CANCELED: ["#303f9f", "#e8eaf6"],
    BUSY: ["#303f9f", "#e8eaf6"],
    NO_ANSWER: ["#303f9f", "#e8eaf6"],
    ANSWERED: ["#303f9f", "#e8eaf6"]
};

export const colorChart = {
    total: [colors.indigo[600], alpha(colors.indigo[600], 0.2)],
    requested: [colors.teal[600], alpha(colors.teal[600], 0.2)],
    accepted: ["#1976d2", "#e3f2fd"],
    error: ["#f57c00", "#fff3e0"],
    delivered: ["#689f38", "#f1f8e9"],
    apiFailure: ["#d32f2f", "#ffebee"],
    deliveryFailure: ["#d32f2f", "#ffebee"],
    failed: ["#d32f2f", "#ffebee"],
    noStatus: [colors.grey[600], alpha(colors.grey[600], 0.2)],
    read: ["#303f9f", "#e8eaf6"],
    answered: ["#303f9f", "#e8eaf6"],
    clicked: ["#303f9f", "#e8eaf6"],
    dismissed: ["#303f9f", "#e8eaf6"],
    no_answer: ["#303f9f", "#e8eaf6"],
    opened: ["#303f9f", "#e8eaf6"],
    canceled: ["#303f9f", "#e8eaf6"],
    busy: ["#303f9f", "#e8eaf6"]
};

export const metricsLabelMapping = {
    total: "Total",
    requested: "Processed",
    accepted: "Sent",
    delivered: "Delivered",
    error: "Error",
    failed: "Failed",
    apiFailure: "Api Failure",
    deliveryFailure: "Delivery Failure",
    noStatus: "No Status",
    read: "Read",
    answered: "Answered",
    opened: "Opened",
    clicked: "Clicked",
    busy: "Busy",
    no_answer: "No_answer",
    dismissed: "Dismissed",
    canceled: "Canceled"
};

export function getMessageStatusIcon(status, children) {
    if (status === "SENT") {
        return <CheckRoundedIcon {...children} fontSize="small" htmlColor="#1c1c1c" />;
    }
    if (status === "DELIVERED") {
        return <DoneAllIcon {...children} fontSize="small" htmlColor="#68CA7D" />;
    }
    if (status === "CLICKED") {
        return <DoneAllIcon {...children} fontSize="small" htmlColor="#DDD4EE" />;
    }
    if (status === "DISMISSED") {
        return <DoneAllIcon {...children} fontSize="small" htmlColor="#DDD4EE" />;
    }
    if (status === "OPENED") {
        return <DoneAllIcon {...children} fontSize="small" htmlColor="#DDD4EE" />;
    }
    if (status === "PROCESSING") {
        return <HourglassFullRoundedIcon {...children} fontSize="small" htmlColor="#FFDFAC" />;
    }
    if (status === "FAILED") {
        return <CloseRoundedIcon {...children} fontSize="small" htmlColor="#FFCECD" />;
    }
    if (status === "CANCELED") {
        return <DoneAllIcon {...children} fontSize="small" htmlColor="#DDD4EE" />;
    }
    if (status === "BUSY") {
        return <DoneAllIcon {...children} fontSize="small" htmlColor="#DDD4EE" />;
    }
    if (status === "NO_ANSWER") {
        return <DoneAllIcon {...children} fontSize="small" htmlColor="#DDD4EE" />;
    }
    if (status === "ANSWERED") {
        return <DoneAllIcon {...children} fontSize="small" htmlColor="#DDD4EE" />;
    }
}

export const getMetricKeyFromLabel = function(label) {
    const keys = Object.keys(metricsLabelMapping);
    for (let i = 0; i < i < keys.length; i++) {
        if (metricsLabelMapping[keys[i]] === label) {
            return keys[i];
        }
    }
    return keys[0];
};

export const convertAddProviderToFormObj = function(channel, provider) {
    return {
        name: "",
        config: {
            format: provider["config"][channel]
        },
        channel: channel,
        provider: provider["key"]
    };
};

export const convertUpdateProviderToFormObj = function(allProviders, provider) {
    var prov = allProviders.find(item => item["key"] === provider["provider"]);
    var result = null;
    if (prov) {
        result = { ...provider };
        result["config"] = {
            ...{
                format: prov["config"][provider["channel"].toLowerCase()]
            },
            ...provider["config"]
        };
    }
    return result;
};

export const logger = data => {
    if (process.env.NODE_ENV === "development") {
        console.log(data);
    }
};

export const isDevelopment = () => {
    return process.env.REACT_APP_ENV !== "prod" || window.location.host.includes("localhost");
};

export function encodeJson(json) {
    return lz.encodeBase64(lz.compress(json));
}

export function decodeJson(json) {
    return lz.decompress(lz.decodeBase64(json));
}

export function openInNewTab(url) {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
}

export const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
];
