import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import clsx from "clsx";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Typography,
    makeStyles,
    Radio,
    useTheme,
    Box,
    IconButton,
    Divider
} from "@material-ui/core";

import { useSnackbar } from "notistack";
import CloseIcon from "@material-ui/icons/Close";
import useIsMountedRef from "src/hooks/useIsMountedRef";
import * as utils from "src/core/utils/utils";
import * as analytics from "src/core/components/analytics";
import { sendVerificationEmail } from "src/core/repo/userRepo";
import VerifiedUserOutlinedIcon from "@material-ui/icons/VerifiedUserOutlined";

const useStyles = makeStyles(theme => ({
    root: {
        margin: theme.spacing(1)
    },
    tableCell: {
        borderBottom: "none"
    },
    provider: {
        height: 40,
        width: 40,
        "& + &": {
            marginLeft: theme.spacing(1)
        }
    }
}));

function VerifyEmailDialog({
    className,
    user,
    openVerifyEmailDialog,
    handleCloseVerifyEmailDialog,
    ...rest
}) {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();

    const handleVerifyEmail = async () => {
        try {
            await dispatch(sendVerificationEmail(user["email"]));
            enqueueSnackbar("Email verification link sent successfully", {
                variant: "success"
            });
            handleCloseVerifyEmailDialog();
            analytics.logEvent(analytics.SEND_VERIFICATION_EMAIL_BUTTON_CLICK,{
                'email':user["email"],
                'res':"Email verification link sent successfully"
            });  
        } catch (error) {
            enqueueSnackbar(error.message ? error.message : "Error occured", {
                variant: "error"
            }); 
            analytics.logEvent(analytics.SEND_VERIFICATION_EMAIL_BUTTON_CLICK,{
                'email':user["email"],
                'res':error.message ? error.message : "Error occured"
            }); 
        }
    };

    return (
        <Dialog
            open={openVerifyEmailDialog}
            fullWidth={true}
            maxWidth={"sm"}
            onClose={handleCloseVerifyEmailDialog}
            keepMounted
            aria-labelledby="form-dialog-title"
        >
            <DialogTitle id="form-dialog-title" style={{ paddingTop: 5, paddingBottom: 5 }}>
                <Box display="flex" alignItems="center" justifyContent="space-between">
                    <Box display="flex" alignItems="center">
                        <VerifiedUserOutlinedIcon />
                        <Box ml={1}>
                            <Typography variant="h4">{"Verify Email"}</Typography>
                        </Box>
                    </Box>
                    <IconButton onClick={() => handleCloseVerifyEmailDialog()}>
                        <CloseIcon />
                    </IconButton>
                </Box>
            </DialogTitle>
            <DialogContent dividers={true}>
                <Box my={1}>
                    {/* <Typography variant="body1">Hi {user["username"]}!</Typography> */}
                    <Typography styles={{ marginTop: 10 }}>
                        Your email <b>{user["email"]}</b> is not verified yet. To unlock all
                        features of your Raven account, please verify your email by clicking the
                        button below.
                    </Typography>
                </Box>
            </DialogContent>
            <DialogActions>
                <Box m={1}>
                    <Button
                        color="secondary"
                        type="submit"
                        variant="contained"
                        onClick={handleVerifyEmail}
                    >
                        SEND VERIFICATION EMAIL
                    </Button>
                </Box>
            </DialogActions>
        </Dialog>
    );
}

VerifyEmailDialog.propTypes = {
    className: PropTypes.string
};

export default VerifyEmailDialog;
