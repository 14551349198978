import produce from "immer";
import {
    GET_APP_USERS_REQUEST,
    GET_APP_USERS_SUCCESS,
    GET_APP_USERS_FAILURE,
    CREATE_UPDATE_APP_USER_SUCCESS
} from "../events/appUsers";

const initialState = {
    users: [],
    appId: null
};

const appUserReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_APP_USERS_REQUEST: {
            return produce(state, draft => {
                if (!state.users.length) {
                    draft.users = [];
                }
            });
        }

        case GET_APP_USERS_SUCCESS: {
            const { users, appId, lastRequestId } = action.payload;
            return produce(state, draft => {
                if (draft.appId === appId && lastRequestId) {
                    draft.appId = appId;
                    draft.users.push(...users);
                } else {
                    draft.appId = appId;
                    draft.users = users;
                }
            });
        }

        case GET_APP_USERS_FAILURE: {
            return produce(state, draft => {
                // Maybe store error
                draft.users = [];
            });
        }

        case GET_APP_USERS_FAILURE: {
            return produce(state, draft => {
                // Maybe store error
                draft.users = [];
            });
        }

        case CREATE_UPDATE_APP_USER_SUCCESS: {
            const user = action.payload;
            return produce(state, draft => {
                if (Object.keys(draft.users).length === 0) {
                    draft.users = {
                        users: [user]
                    };
                } else {
                    //check if user id exists
                    let found = false;
                    for (var i = 0; i < draft.users.length; i++) {
                        if (draft.users[i]["user_sid"] === user["user"]["user_sid"]) {
                            draft.users[i] = user["user"];
                            found = true;
                            break;
                        }
                    }

                    if (found === false) {
                        draft.users.push(user);
                    }
                }
            });
        }

        default: {
            return state;
        }
    }
};

export default appUserReducer;
