/* eslint-disable no-use-before-define */
/* eslint-disable react/prop-types */
import React, { useState } from "react";
import {
    makeStyles,
    useTheme,
    Dialog,
    DialogTitle,
    DialogContent,
    Typography,
    IconButton,
    Box,
    ListItemSecondaryAction
} from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Close from "@material-ui/icons/Close";
import { ChevronRight, Description, Help } from "@material-ui/icons";
import { useSnackbar } from "notistack";
import Contactus from "./Contactus";
import { Slack as SlackIcon } from "react-feather";
import { SLACK_COMMUNITY_URL } from "src/core/api/url";

const useStyles = makeStyles(theme => ({
    root: {
        width: "100%",
        backgroundColor: theme.palette.background.paper
    }
}));

function HelpSupport({ handleClose }) {
    const classes = useStyles();
    const theme = useTheme();
    const [openContectUsDialog, setOpenContactUsDialog] = useState(false);
    const { enqueueSnackbar } = useSnackbar();

    const handleOpenContectUsDialog = () => {
        setOpenContactUsDialog(true);
    };

    const handleCloseContectUsDialog = () => {
        setOpenContactUsDialog(false);
    };

    return openContectUsDialog ? (
        <Contactus
            handleCloseContectUsDialog={handleCloseContectUsDialog}
            handleClose={handleClose}
        />
    ) : (
        <Dialog
            fullWidth
            maxWidth="sm"
            aria-labelledby="confirmation-dialog-title"
            open={true}
            onClose={handleClose}
        >
            <DialogTitle disableTypography>
                <Box display="flex" alignItems="center" justifyContent="space-between">
                    <Typography variant="h5">Help &amp; Support</Typography>
                    <IconButton onClick={handleClose} size="small">
                        <Close fontSize="small" />
                    </IconButton>
                </Box>
            </DialogTitle>
            <DialogContent dividers style={{ padding: 0 }}>
                <div className={classes.root}>
                    <List component="nav">
                        <ListItem
                            button
                            divider
                            onClick={e => window.open("https://docs.ravenapp.dev", "_blank")}
                        >
                            <ListItemIcon style={{ marginRight: 10 }}>
                                <Description htmlColor={theme.palette.secondary.light} />
                            </ListItemIcon>
                            <ListItemText
                                primary={
                                    <Typography variant="subtitle1">
                                        Developer Documentation
                                    </Typography>
                                }
                                secondary={
                                    <Typography variant="body2" color="textSecondary">
                                        Learn more about how the platform works and check out Raven
                                        APIs.
                                    </Typography>
                                }
                            />
                            <ListItemSecondaryAction>
                                <ChevronRight color="disabled" />
                            </ListItemSecondaryAction>
                        </ListItem>
                        <ListItem
                            button
                            divider
                            onClick={e => window.open(SLACK_COMMUNITY_URL, "_blank")}
                        >
                            <ListItemIcon style={{ marginRight: 10 }}>
                                {/* <img
                                    src={"https://a.slack-edge.com/bv1-9/slack_logo-ebd02d1.svg"}
                                    height={25}
                                    width={25}
                                /> */}
                                <SlackIcon color={theme.palette.secondary.light} fontSize="small" />
                            </ListItemIcon>
                            <ListItemText
                                primary={
                                    <Typography variant="subtitle1">Slack Community</Typography>
                                }
                                secondary={
                                    <Typography variant="body2" color="textSecondary">
                                        Ask questions and help others
                                    </Typography>
                                }
                            />
                            <ListItemSecondaryAction>
                                <ChevronRight color="disabled" />
                            </ListItemSecondaryAction>
                        </ListItem>
                        {/* <ListItem
                            button
                            divider
                            onClick={() => {
                                handleOpenContectUsDialog();
                            }}
                        >
                            <ListItemIcon style={{ marginRight: 10 }}>
                                <Help color={theme.palette.divider} />
                            </ListItemIcon>
                            <ListItemText
                                primary={<Typography variant="subtitle1">Contact Us</Typography>}
                                secondary={
                                    <Typography variant="body2" color="textSecondary">
                                        Let us know if there's an issue or a question by mailing us
                                        to: <b>support@ravenapp.dev</b>
                                    </Typography>
                                }
                            />
                            <ListItemSecondaryAction>
                                <ChevronRight color="disabled" />
                            </ListItemSecondaryAction>
                        </ListItem> */}
                    </List>
                </div>
            </DialogContent>
        </Dialog>
    );
}

HelpSupport.propTypes = {};

export default HelpSupport;
