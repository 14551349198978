/* eslint-disable no-param-reassign */
import produce from "immer";
import {
    UPDATE_EVENT_SUCCESS,
    UPDATE_EVENT_CHANNELS_SUCCESS,
    UPDATE_EVENT_FAILURE,
    CREATE_EVENT_SUCCESS,
    CREATE_EVENT_FAILURE,
    GET_EVENTS_REQUEST,
    GET_EVENTS_SUCCESS,
    GET_EVENTS_FAILURE,
    GET_EVENT_BY_ID_SUCCESS,
    GET_EVENT_BY_ID_FAILURE,
    GET_EVENTS_LOGS_REQUEST,
    GET_EVENTS_LOGS_SUCCESS,
    GET_EVENTS_LOGS_FAILURE,
    ARCHIVE_EVENT_SUCCESS,
    UPDATE_EVENT_TEMPLATES_SUCCESS,
    COPY_EVENT_PROD_SUCCESS
} from "../events/templateEvents";

const initialState = {
    appId: null,
    events: [],
    logs: []
};

const eventsReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_EVENTS_REQUEST: {
            return produce(state, draft => {
                if (!state.events.length) {
                    draft.events = [];
                }
            });
        }

        case GET_EVENTS_SUCCESS: {
            const { events } = action.payload;
            return produce(state, draft => {
                draft.events = events;
            });
        }

        case GET_EVENTS_FAILURE: {
            return produce(state, draft => {
                // Maybe store error
                draft.events = [];
            });
        }

        case GET_EVENT_BY_ID_SUCCESS: {
            const { event } = action.payload;
            return produce(state, draft => {
                if (!state.events.length) {
                    draft.events = [event];
                } else {
                    draft.events = state.events.map(item =>
                        item.event_sid === event.event_sid ? event : item
                    );
                }
            });
        }

        case GET_EVENT_BY_ID_FAILURE: {
            return produce(state, draft => {
                // Maybe store error
                // draft.events = [];
            });
        }

        case UPDATE_EVENT_SUCCESS: {
            const { event } = action.payload;
            return produce(state, draft => {
                if (Array.isArray(state.events) && state.events.length) {
                    // array exists and is not empty
                    let c = state.events.map(t => {
                        if (event.event_sid === t.event_sid) {
                            return { ...t, ...event };
                        } else {
                            return t;
                        }
                    });
                    draft.events = c;
                }
            });
        }

        case UPDATE_EVENT_CHANNELS_SUCCESS: {
            const { eventId, channels } = action.payload;
            return produce(state, draft => {
                if (Array.isArray(state.events) && state.events.length) {
                    // array exists and is not empty
                    let c = state.events.map(t => {
                        if (eventId === t.event_sid) {
                            return { ...t, ...channels };
                        } else {
                            return t;
                        }
                    });
                    draft.events = c;
                }
            });
        }

        case UPDATE_EVENT_TEMPLATES_SUCCESS: {
            const { eventId, templates } = action.payload;
            return produce(state, draft => {
                if (Array.isArray(state.events) && state.events.length) {
                    // array exists and is not empty
                    let c = state.events.map(t => {
                        if (eventId === t.event_sid) {
                            return { ...t, ...templates };
                        } else {
                            return t;
                        }
                    });
                    draft.events = c;
                }
            });
        }

        case UPDATE_EVENT_FAILURE: {
            return produce(state, draft => {
                // Maybe store error
            });
        }

        case CREATE_EVENT_SUCCESS: {
            const { event } = action.payload;
            return produce(state, draft => {
                if (!Array.isArray(state.events) || state.events.length) {
                    draft.events = [];
                }

                draft.events.push(event);
            });
        }

        case CREATE_EVENT_FAILURE: {
            return produce(state, draft => {
                // Maybe store error
            });
        }

        case GET_EVENTS_LOGS_REQUEST: {
            return produce(state, draft => {
                if (!state.logs.length) {
                    draft.logs = [];
                }
            });
        }

        case GET_EVENTS_LOGS_SUCCESS: {
            const { logs, appId, lastRequestId } = action.payload;
            return produce(state, draft => {
                if (draft.appId === appId && lastRequestId) {
                    draft.appId = appId;
                    draft.logs.push(...logs);
                } else {
                    draft.appId = appId;
                    draft.logs = logs;
                }
            });
        }

        case GET_EVENTS_LOGS_FAILURE: {
            return produce(state, draft => {
                // Maybe store error
                draft.logs = [];
            });
        }

        case ARCHIVE_EVENT_SUCCESS: {
            const { eventId } = action.payload;
            return produce(state, draft => {
                draft.events = draft.events.filter(event => event.event_sid !== eventId);
            });
        }

        // case COPY_EVENT_PROD_SUCCESS: {
        //     const { copyEvent } = action.payload;
        //     return produce(state, draft => {
        //         draft.events = [copyEvent, draft.events];
        //     });
        // }

        default: {
            return state;
        }
    }
};

export default eventsReducer;
