import produce from 'immer';
import {
    GET_MESSAGE_EVENTS_REQUEST,
    GET_MESSAGE_EVENTS_SUCCESS,
    GET_MESSAGE_EVENTS_FAILURE,
} from '../events/messageEvents';

import {ARCHIVE_EVENT_SUCCESS} from '../events/templateEvents'

const initialState = {
  eventsAppId: "",
  events: [],
};

const messageEventReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_MESSAGE_EVENTS_REQUEST: {
      const { eventsAppId } = action.payload;
      return produce(state, (draft) => {
        if (eventsAppId != draft.eventsAppId) {
          draft.events = [];
        }
      });
    }

    case GET_MESSAGE_EVENTS_SUCCESS: {
      const { events } = action.payload;
      const { eventsAppId } = action.payload;
      return produce(state, (draft) => {
        draft.events = events;
        draft.eventsAppId = eventsAppId;
      });
    }

    case GET_MESSAGE_EVENTS_FAILURE: {
      return produce(state, (draft) => {
        // Maybe store error
        draft.events = [];
      });
    }
    default: {
      return state;
    }
    }
}

export default messageEventReducer;