import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import SplashScreen from "src/components/SplashScreen";
import { loginSuccess, logout, loginRequest, authRefresh } from "src/core/events/userEvents";
import userService from "src/core/api/userService";
import { useHistory, useLocation } from "react-router";
import {
    CHANGE_PWD_PATH,
    LOGIN_PATH,
    JOIN_ACCOUNT_PATH,
    REGISTER_PATH,
    GET_STARTED_PATH,
    PENDING_INVITE_PATH,
    JOIN_ORGANIZATION_PATH,
    VERIFY_EMAIL_PATH
} from "../Routes";
import { VERIFY_DOMAIN_EMAIL } from "src/core/api/url";

function Auth({ children }) {
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    const [isLoading, setLoading] = useState(true);

    useEffect(() => {
        const initAuth = async () => {
            userService.setAxiosInterceptors({
                onLogout: () => dispatch(logout())
            });

            if (!userService.isAuthenticated()) {
                await userService
                    .refreshAuthToken()
                    .then(() => {})
                    .catch(function(error) {
                        /\/account\/.*\/join/.test(location.pathname) ||
                        location.pathname === CHANGE_PWD_PATH ||
                        location.pathname === JOIN_ACCOUNT_PATH ||
                        location.pathname === LOGIN_PATH ||
                        location.pathname === REGISTER_PATH ||
                        location.pathname === PENDING_INVITE_PATH ||
                        location.pathname === JOIN_ORGANIZATION_PATH ||
                        location.pathname === VERIFY_EMAIL_PATH
                            ? history.push(location)
                            : history.push(GET_STARTED_PATH);
                    });
            }

            setLoading(false);
        };

        initAuth();
    }, [dispatch]);

    if (isLoading) {
        return <SplashScreen />;
    }

    return children;
    //test
}

Auth.propTypes = {
    children: PropTypes.any
};

export default Auth;
