import jwtDecode from "jwt-decode";
import axios from "./axios";
import { SEND_MESSAGE, SEND_CONTACT_US_EMAIL } from "./url";

class SendMessageService {
    sendMessage = (appId, payload) =>
        new Promise((resolve, reject) => {
            axios
                .post(SEND_MESSAGE.format(appId), payload)
                .then(response => {
                    if (response) {
                        resolve(response.data);
                    } else {
                        reject(response);
                    }
                })
                .catch(error => {
                    console.log(error);
                    reject(error);
                });
        });

    sendContactUsEmail = (appId, payload, apiKey) =>
        new Promise((resolve, reject) => {
            axios
                .post(SEND_CONTACT_US_EMAIL.format(appId), payload, {
                    headers: {
                        Authorization: `AuthKey ${apiKey}`
                    }
                })
                .then(response => {
                    if (response) {
                        resolve(response.data);
                    } else {
                        reject(response);
                    }
                })
                .catch(error => {
                    console.log(error);
                    reject(error);
                });
        });
}

const sendMessageService = new SendMessageService();

export default sendMessageService;
