import React from "react";
import { useSelector } from "react-redux";
import { NavLink as RouterLink } from "react-router-dom";
import PropTypes from "prop-types";
import clsx from "clsx";
import {
    AppBar,
    Box,
    Hidden,
    IconButton,
    Button,
    Toolbar,
    makeStyles,
    SvgIcon,
    Typography,
    useTheme,
    Tooltip
} from "@material-ui/core";
import { SettingsOutlined } from "@material-ui/icons";
import AppModeBanner from "../AppMode/AppModeBanner";
import AppModeMenu from "../AppMode/AppModeMenu";
import { Menu as MenuIcon } from "react-feather";
import { THEMES } from "src/constants";
import Account from "./Account";
import * as analytics from "src/core/components/analytics";
import EmailVerificationBanner from "src/components/EmailVerificationBanner";
import { BiSupport } from "react-icons/bi";

const useStyles = makeStyles(theme => ({
    root: {
        zIndex: theme.zIndex.drawer + 100,
        ...(theme.name === THEMES.LIGHT
            ? {
                  boxShadow: "none",
                  backgroundColor: theme.palette.primary.main
              }
            : {}),
        ...(theme.name === THEMES.ONE_DARK
            ? {
                  backgroundColor: theme.palette.background.default
              }
            : {})
    },
    toolbar: {
        minHeight: 64
    },
    active: {
        backgroundColor: theme.palette.primary.dark
    },
    button: {
        color: "#fafafa",
        textTransform: "none",
        letterSpacing: 0,
        paddingLeft: 12,
        paddingRight:12,
        "&:hover": {
            backgroundColor: theme.palette.primary.dark,
            color: "#fafafa"
        },
        "&:focus": {
            color: "#fafafa",
            textDecoration: "none"
        }
    },
    iconButton: {
        color: "#fafafa",
        // padding: 5,
        "&:hover": {
            backgroundColor: theme.palette.primary.dark,
            color: "#fafafa"
        },
        "&:focus": {
            color: "#fafafa"
        }
    }
}));

function TopBar({ className, onAppSelected, selectedApp, apps, onMobileNavOpen, ...rest }) {
    const theme = useTheme();
    const classes = useStyles();
    const { account } = useSelector(state => state.account);
    const handleGetStarted = () => {
        analytics.logEvent(analytics.POPUP_GET_STARTED);
    };
    return (
        <AppBar className={clsx(classes.root, className)} {...rest}>
            <Toolbar className={classes.toolbar}>
                <Hidden lgUp>
                    <IconButton
                        className={classes.menuButton}
                        color="inherit"
                        onClick={onMobileNavOpen}
                    >
                        <SvgIcon fontSize="small">
                            <MenuIcon />
                        </SvgIcon>
                    </IconButton>
                </Hidden>
                <Hidden mdDown>
                    <Box>
                        <img src="/static/raven-white.png" height="30px" width="30px"></img>
                    </Box>
                    <Box ml={5} />
                    <Box display="flex" alignItems={"center"}>
                        {/* <Button
                            activeClassName={classes.active}
                            className={classes.button}
                            component={RouterLink}
                            exact
                            to="/app/dashboard/metrics"
                            size="small"
                        >
                            Metrics
                        </Button>
                        <Box ml={1} />
                        <Button
                            activeClassName={classes.active}
                            className={classes.button}
                            component={RouterLink}
                            exact
                            size="small"
                            to="/app/dashboard/logs"
                        >
                            Logs
                        </Button>
                        <Box mx={2}>
                            <Typography variant="h5" style={{ color: theme.palette.primary.light }}>
                                |
                            </Typography>
                        </Box>
                        <Button
                            activeClassName={classes.active}
                            className={classes.button}
                            component={RouterLink}
                            exact
                            size="small"
                            to="/app/management/events"
                        >
                            Events
                        </Button>
                        <Box ml={1} /> */}
                        <Button
                            activeClassName={classes.active}
                            className={classes.button}
                            component={RouterLink}
                            exact
                            size="small"
                            to="/app/management/templates"
                        >
                            Templates
                        </Button>
                        {/* <Box ml={1} />
                        {account["preferences"] !== null &&
                            account["preferences"]["user_management_enabled"] && (
                                <Button
                                    activeClassName={classes.active}
                                    className={classes.button}
                                    component={RouterLink}
                                    exact
                                    size="small"
                                    to="/app/management/users"
                                >
                                    Users
                                </Button>
                            )}
                        <Box ml={1} />
                        <Button
                            activeClassName={classes.active}
                            className={classes.button}
                            component={RouterLink}
                            exact
                            size="small"
                            to="/app/management/integrations"
                        >
                            Integrations
                        </Button> */}
                    </Box>
                </Hidden>
                <Box flexGrow={1} />
                <AppModeMenu onAppSelected={onAppSelected} />
                <Box mr={2} />
                <Tooltip title="Settings">
                    <IconButton
                        activeClassName={classes.active}
                        component={RouterLink}
                        className={classes.iconButton}
                        to="/app/management/settings"
                    >
                        <SettingsOutlined fontSize="small" />
                    </IconButton>
                </Tooltip>
                <Box mr={2} />
                <Button
                    className={classes.button}
                    size="small"
                    startIcon={<BiSupport fontSize="small" />}
                    onClick={() => {
                        window.$chatwoot.toggle();
                    }}
                >
                    Support
                </Button>
                <Box ml={3}>
                    <Account />
                </Box>
            </Toolbar>
            <EmailVerificationBanner />
            <AppModeBanner />
        </AppBar>
    );
}

TopBar.propTypes = {
    className: PropTypes.string,
    onMobileNavOpen: PropTypes.func
};

export default TopBar;
