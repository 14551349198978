import React, { useRef, useState } from "react";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import {
    Avatar,
    Box,
    ButtonBase,
    Menu,
    MenuItem,
    Typography,
    makeStyles,
    Divider,
    ListItemIcon,
    Button
} from "@material-ui/core";
import { logout } from "src/core/events/userEvents";
import * as analytics from "src/core/components/analytics";
import { GET_STARTED_PATH } from "src/Routes";
import { MdLogout, MdOutlineMail, MdOutlineVpnKey, MdOutlineContentCopy } from "react-icons/md";
import { CgProfile } from "react-icons/cg";
import { TbFileDescription } from "react-icons/tb";
import { FiSettings } from "react-icons/fi";
import { RAVEN_DOCS_URL } from "src/core/api/url";
import CopyToClipboard from "src/components/CopyToClipboard";

const useStyles = makeStyles(theme => ({
    avatar: {
        height: 25,
        width: 25,
        padding: 15,
        marginRight: theme.spacing(1),
        backgroundColor: "#f1f1f1",
        color: theme.palette.secondary.main
    },
    popover: {
        width: 300,
        marginTop: 17,
        padding: 0
    },
    menuItemNoHover: {
        display: "flex",
        alignItems: "center",
        padding: "8px 16px"
    },
    menuItem: {
        paddingTop: 10,
        paddingBottom: 10
    },
    menuItemTitle: {
        fontSize: 15
    }
}));

function Account({ handleGetStarted }) {
    const classes = useStyles();
    const history = useHistory();
    const ref = useRef(null);
    const dispatch = useDispatch();
    const { user, account } = useSelector(state => state.account);
    const { enqueueSnackbar } = useSnackbar();
    const [isOpen, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleLogout = async () => {
        try {
            analytics.logEvent(analytics.LOGOUT);
            analytics.logEvent(analytics.SIGNED_OUT, {
                username: user.username,
                context: {
                    groupId: user.account_id
                }
            });
            handleClose();
            await dispatch(logout());
            window.$chatwoot.reset();
            history.push(GET_STARTED_PATH);
        } catch (error) {
            enqueueSnackbar("Unable to logout", {
                variant: "error"
            });
        }
    };

    const formatSecretKey = () => {
        const secretKey = account.secret_key;
        const n = secretKey.length;
        return secretKey.substring(0, 12) + "..." + secretKey.substring(n - 7, n);
    };

    const navigateToSetting = tab => {
        let searchQueryObj = {
            tab: tab
        };
        history.push({
            pathname: "/app/management/settings",
            search: "?" + new URLSearchParams(searchQueryObj).toString()
        });
        handleClose();
    };

    return (
        <>
            <Box
                display="flex"
                alignItems="center"
                component={ButtonBase}
                onClick={handleOpen}
                ref={ref}
            >
                <Avatar alt={user.username} src="." className={classes.avatar} />
                {/* <Hidden smDown>
                    <Typography variant="h6" color="inherit">
                        {user.username}
                    </Typography>
                </Hidden> */}
            </Box>
            <Menu
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom"
                }}
                PaperProps={{ className: classes.popover }}
                MenuListProps={{ style: { padding: 0 } }}
                getContentAnchorEl={null}
                anchorEl={ref.current}
                open={isOpen}
            >
                <Box className={classes.menuItemNoHover}>
                    <ListItemIcon></ListItemIcon>
                    <Box>
                        <Typography variant="subtitle2" style={{ color: "#9699a4" }}>
                            Account
                        </Typography>
                        <Typography
                            variant="inherit"
                            style={{ fontWeight: 550 }}
                            className={classes.menuItemTitle}
                            gutterBottom
                        >
                            {account.name}
                        </Typography>
                    </Box>
                </Box>
                <Divider />
                {["ADMIN", "EDITOR"].includes(user.access_role) && (
                    <CopyToClipboard copyText={account.secret_key}>
                        <MenuItem className={classes.menuItem}>
                            <ListItemIcon>
                                <MdOutlineVpnKey fontSize="large" />
                            </ListItemIcon>
                            <Box>
                                <Typography
                                    variant="subtitle2"
                                    gutterBottom
                                    style={{ color: "#9699a4" }}
                                >
                                    API Key
                                </Typography>
                                <Typography
                                    variant="inherit"
                                    style={{ display: "flex" }}
                                    className={classes.menuItemTitle}
                                    noWrap
                                >
                                    {formatSecretKey()}
                                    <ListItemIcon>
                                        <MdOutlineContentCopy
                                            fontSize="large"
                                            style={{ marginLeft: 16 }}
                                        ></MdOutlineContentCopy>
                                    </ListItemIcon>
                                </Typography>
                            </Box>
                        </MenuItem>
                    </CopyToClipboard>
                )}
                <MenuItem onClick={() => navigateToSetting("account")} className={classes.menuItem}>
                    <ListItemIcon>
                        <FiSettings fontSize="large" />
                    </ListItemIcon>
                    <Typography variant="inherit" className={classes.menuItemTitle} noWrap>
                        Account Settings
                    </Typography>
                </MenuItem>
                <MenuItem
                    onClick={e => {
                        window.open(RAVEN_DOCS_URL, "_blank");
                        handleClose();
                    }}
                    className={classes.menuItem}
                >
                    <ListItemIcon>
                        <TbFileDescription fontSize="large" />
                    </ListItemIcon>
                    <Typography variant="body2" className={classes.menuItemTitle} noWrap>
                        Documentation
                    </Typography>
                </MenuItem>
                <Divider />
                <MenuItem onClick={() => navigateToSetting("profile")} className={classes.menuItem}>
                    <ListItemIcon>
                        <MdLogout fontSize="large" />
                    </ListItemIcon>
                    <Box>
                        <Typography variant="subtitle2" gutterBottom style={{ color: "#9699a4" }}>
                            Logged In as
                        </Typography>
                        <Typography variant="inherit" className={classes.menuItemTitle} noWrap>
                            {user.email}
                        </Typography>
                    </Box>
                </MenuItem>
                <Box
                    className={classes.menuItemNoHover}
                    onClick={handleLogout}
                    style={{ paddingBottom: 12 }}
                >
                    <ListItemIcon></ListItemIcon>
                    <Button variant="outlined" style={{ width: 200 }} size="small">
                        Logout
                    </Button>
                </Box>
            </Menu>
        </>
    );
}

export default Account;
