import React from 'react';

function Logo(props) {
  return (
    <img
      alt="Logo"
      src="/static/raven.png"
      height="40px"
      width="40px"
      {...props}
    />
  );
}

export default Logo;
