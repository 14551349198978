import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core";
import NavBar from "./NavBar";
import TopBar from "./TopBar";
import { useSelector, useDispatch } from "react-redux";
import useIsMountedRef from "src/hooks/useIsMountedRef";
import { setSelectedApp, getApps, getUserAndAccount } from "src/core/repo/userRepo";
import { GET_APPS_REQUEST } from "src/core/events/userEvents";
import LoadingScreen from "src/components/LoadingScreen";
import { createLoadingSelector } from "src/core/utils/utils";
import { getActionName } from "src/core/reducers/loadingReducer";
import * as analytics from "src/core/components/analytics";
import { getPreferences, setPreferences } from "src/core/components/FirebasePreferences";
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        display: "flex",
        height: "100%",
        overflow: "hidden",
        width: "100%"
    },
    wrapper: {
        display: "flex",
        flex: "1 1 auto",
        overflow: "hidden",
        paddingTop: 64
        // [theme.breakpoints.up("lg")]: {
        //     paddingLeft: 256
        // }
    },
    contentContainer: {
        display: "flex",
        flex: "1 1 auto",
        overflow: "hidden"
    },
    content: {
        flex: "1 1 auto",
        height: "100%",
        overflow: "auto"
    }
}));

function DashboardLayout({ children }) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const isMountedRef = useIsMountedRef();
    const [isMobileNavOpen, setMobileNavOpen] = useState(false);
    const { apps } = useSelector(state => state.account);
    const { user, account } = useSelector(state => state.account);
    const { selectedApp } = useSelector(state => state.account);
    const loadingSelector = createLoadingSelector([getActionName(GET_APPS_REQUEST)]);
    const isLoading = useSelector(state => loadingSelector(state)); 
    const onAppSelected = app => {
        if (!user["verified"]) {
            dispatch(setSelectedApp(apps.find(apps => apps.type === "development")));
        } else {
            dispatch(setSelectedApp(app));
        }
    };

    const getAndUpdatePreferences = () => {
        let ref = getPreferences(user.id, "selectedApp");
        ref.on("value", snapshot => {
            const data = snapshot.val();
            if (data && apps) {
                const app = apps.find(app => app.app_sid === data);
                if (!selectedApp || (selectedApp && selectedApp.app_sid !== data)) {
                    if (app) {
                        onAppSelected(app);
                    }
                    else {
                        onAppSelected(apps[0]);
                        setPreferences(user.id, "selectedApp", apps[0].app_sid);
                    }
                }
            } else if (!data && apps) {
                setPreferences(user.id, "selectedApp", apps[0].app_sid);
            }
        });
    };
    useEffect(() => {
        // getAndUpdatePreferences();
        const ref = getPreferences(user.id, "selectedApp");
        const newData = ref.on("value", snapshot => {
            const data = snapshot.val();
            if (data && apps) {
                const app = apps.find(app => app.app_sid === data);
                if (!selectedApp || (selectedApp && selectedApp.app_sid !== data)) {
                    if (app) {
                        onAppSelected(app);
                    } else {
                        onAppSelected(apps[0]);
                        setPreferences(user.id, "selectedApp", apps[0].app_sid);
                    }
                }
            } else if (!data && apps) {
                setPreferences(user.id, "selectedApp", apps[0].app_sid);
            }
        });

        return () => {
            ref.off("value", newData);
        };
    }, [isMountedRef, apps]);

    useEffect(() => {
        window.$chatwoot.setUser(user.id, {
            email: user.email,
            name: user.username
        });
        analytics.setUserId(user.id, {
            name: user.username,
            email: user.email,
            role: user.access_role,
            account_id: user.account_id
        });
        analytics.setUserProperties({ email: user.email, name: user.username });

        if (apps) {
            analytics.logGroup(account.id, {
                account_name: account.name,
                $name: account.name,
                account_id: account.id,
                apps: apps.map(it => it.name),
                last_selected_app: selectedApp.name,
                last_selected_app_id: selectedApp.app_sid
            });
        }

        // analytics.setGroupProperties("account_id",  user );
        analytics.logEvent(analytics.HOME_PAGE);

        dispatch(getUserAndAccount());
        dispatch(getApps());
    }, [isMountedRef]);

    useEffect(() => {
        if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
            // dev code
        } else {
            // tawkTo("5f4ff71f4704467e89ebb7f7");
        }
    }, [isMountedRef]);

    if (isLoading || apps == null) {
        return <LoadingScreen />;
    }

    // if (apps.length > 0 && selectedApp == null) {
    //     dispatch(setSelectedApp(apps[0]));
    // }

    // localStorage.removeItem("onboarding_apps");

    return (
        <div className={classes.root} style={{ padding: 17 }}>
            {selectedApp && (
                <>
                    <TopBar
                        selectedApp={selectedApp}
                        onAppSelected={onAppSelected}
                        apps={apps}
                        onMobileNavOpen={() => setMobileNavOpen(true)}
                    />
                    <NavBar
                        onMobileClose={() => setMobileNavOpen(false)}
                        openMobile={isMobileNavOpen}
                        apps={apps}
                        selectedApp={selectedApp}
                        onAppSelected={onAppSelected}
                    />
                    <div className={classes.wrapper}>
                        <div className={classes.contentContainer}>
                            <div className={classes.content}>{children}</div>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
}

DashboardLayout.propTypes = {
    children: PropTypes.any
};

export default DashboardLayout;
