export const GET_APP_USERS_REQUEST = "@template/get-app-users-request";
export const GET_APP_USERS_SUCCESS = "@template/get-app-users-success";
export const GET_APP_USERS_FAILURE = "@template/get-app-users-failure";
export const CREATE_UPDATE_APP_USER_SUCCESS = "@template/create-update-app-user-success";

export function getAppUsersRequest() {
    return {
        type: GET_APP_USERS_REQUEST
    };
}

export function getAppUsersSuccess(users, appId, lastRequestId) {
    return {
        type: GET_APP_USERS_SUCCESS,
        payload: {
            users,
            appId,
            lastRequestId
        }
    };
}

export function getAppUsersFailure() {
    return {
        type: GET_APP_USERS_FAILURE
    };
}

export function createUpdateAppUserSuccess(user) {
    return {
        type: CREATE_UPDATE_APP_USER_SUCCESS,
        payload: {
            user
        }
    };
}
