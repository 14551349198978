export const LOGIN_REQUEST = "@user/login-request";
export const LOGIN_SUCCESS = "@user/login-success";
export const LOGIN_FAILURE = "@user/login-failure";
export const AUTH_REFRESH = "@user/auth-refresh";
export const LOGOUT = "@user/logout";
export const GET_USER_REQUEST = "@user/get-user-request";
export const GET_USER_SUCCESS = "@user/get-user-success";
export const GET_ACCOUNT_REQUEST = "@user/get-account-request";
export const GET_ACCOUNT_SUCCESS = "@user/get-account-success";
export const GET_APPS_REQUEST = "@user/get-apps-request";
export const GET_APPS_SUCCESS = "@user/get-apps-success";
export const GET_APPS_FAILURE = "@user/get-apps-failure";
export const GET_SELECTED_APP_REQUEST = "@user/get-selected-app-request";
export const GET_SELECTED_APP_SUCCESS = "@user/get-selected-app-success";
export const SET_SELECTED_APP_REQUEST = "@user/set-selected-app-request";
export const SET_SELECTED_APP_SUCCESS = "@user/set-selected-app-success";
export const CREATE_APP_SUCCESS = "@user/create-app-success";
export const UPDATE_SELECTED_APP_NAME_SUCCESS = "@user/update-selected-app-name-success";
export const UPDATE_SELECTED_APP_SUCCESS = "@user/update-selected-app-success";
export const DELETE_APP_SUCCESS = "@user/delete-app-success";
export const INVITE_USER_SUCCESS = "@user/invite-user-success";
export const REINVITE_USER_SUCCESS = "@user/reinvite-user-success";
export const DELETE_INVITE_USER_SUCCESS = "@user/delete-invite-user-success";
export const DELETE_MEMBER_SUCCESS = "@user/delete-member-success";
export const ROLE_CHANGE_SUCCESS = "@user/role-change-success";

export function loginRequest() {
    return {
        type: LOGIN_REQUEST
    };
}

export function loginSuccess(account, user, token, refreshToken) {
    return {
        type: LOGIN_SUCCESS,
        payload: {
            account: account,
            user: user,
            token: token,
            refreshToken: refreshToken
        }
    };
}

export function loginFailure() {
    return {
        type: LOGIN_FAILURE
    };
}

export function logout() {
    return {
        type: LOGOUT
    };
}

export function authRefresh(token, refreshToken) {
    return {
        type: AUTH_REFRESH,
        payload: {
            token: token
        }
    };
}

export function getUserRequest() {
    return {
        type: GET_USER_REQUEST
    };
}

export function getUserSuccess(user) {
    return {
        type: GET_USER_SUCCESS,
        payload: {
            user
        }
    };
}

export function getAccountRequest() {
    return {
        type: GET_ACCOUNT_REQUEST
    };
}

export function getAccountSuccess(account) {
    return {
        type: GET_ACCOUNT_SUCCESS,
        payload: {
            account: account
        }
    };
}

export function getAppsRequest() {
    return {
        type: GET_APPS_REQUEST
    };
}

export function getAppsSuccess(apps) {
    return {
        type: GET_APPS_SUCCESS,
        payload: {
            apps
        }
    };
}

export function getAppsFailure() {
    return {
        type: GET_APPS_FAILURE
    };
}

export function getSelectedAppRequest() {
    return {
        type: GET_SELECTED_APP_REQUEST
    };
}

export function setSelectedAppRequest(selectedApp) {
    return {
        type: SET_SELECTED_APP_REQUEST,
        payload: {
            selectedApp
        }
    };
}

export function setSelectedAppSuccess(selectedApp) {
    return {
        type: SET_SELECTED_APP_SUCCESS,
        payload: {
            selectedApp
        }
    };
}

export function updateSelectedAppNameSuccess(appName, updatedAppName) {
    return {
        type: UPDATE_SELECTED_APP_NAME_SUCCESS,
        payload: {
            appName,
            updatedAppName
        }
    };
}

export function updateSelectedAppSuccess(app) {
    return {
        type: UPDATE_SELECTED_APP_SUCCESS,
        payload: {
            app
        }
    };
}

export function createAppSuccess(app) {
    return {
        type: CREATE_APP_SUCCESS,
        payload: {
            app
        }
    };
}

export function deleteAppSuccess(appName) {
    return {
        type: DELETE_APP_SUCCESS,
        payload: {
            appName
        }
    };
}

export function inviteUserSuccess() {
    return {
        type: INVITE_USER_SUCCESS
    };
}

export function reInviteUserSuccess() {
    return {
        type: REINVITE_USER_SUCCESS
    };
}

export function deleteInviteUserSuccess() {
    return {
        type: DELETE_INVITE_USER_SUCCESS
    };
}
export function deleteMemberSuccess() {
    return {
        type: DELETE_MEMBER_SUCCESS
    };
}
export function roleChangeSuccess() {
    return {
        type: ROLE_CHANGE_SUCCESS
    };
}
