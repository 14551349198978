import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import {
    TextField,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Box,
    Typography,
    makeStyles,
    useTheme
} from "@material-ui/core";

import { useSnackbar } from "notistack";
import { createApp } from "src/core/repo/userRepo";
import * as analytics from "src/core/components/analytics";
import { allAppTypes } from "./Environment";
import { getUser } from "src/core/utils/credentials";
import { setPreferences } from "src/core/components/FirebasePreferences";
import { setSelectedApp } from "src/core/repo/userRepo";

const useStyles = makeStyles(theme => ({
    root: {
        margin: theme.spacing(1)
    },
    tableCell: {
        borderBottom: "none"
    },
    provider: {
        height: 40,
        width: 40,
        "& + &": {
            marginLeft: theme.spacing(1)
        }
    }
}));

function AddAccountAppDialog({ className, handleDialogClose: handleDialogClose, ...rest }) {
    const [open, setOpen] = React.useState(true);
    const [name, setName] = React.useState("");
    const [type, setType] = React.useState(allAppTypes[2]);
    const { apps } = useSelector(state => state.account);

    // tracking the touch status
    const [touched, setTouched] = React.useState({
        name: false,
        type: false
    });
    //tracking Validation status
    const [isValidated, setIsValidated] = React.useState(false);

    const user = getUser();
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const theme = useTheme();
    const dispatch = useDispatch();

    const handleClose = success => {
        setOpen(false);
        handleDialogClose(success);
    };

    var errors = {
        name: "",
        type: ""
    };

    useEffect(() => {
        errors = validate(name, type);
    }, [name, type]);

    const handleAddApp = async () => {
        try {
            // Make API request
            let newApp = {};
            newApp.name = name.trim();
            const allApps = [...apps];
            if (allApps.find(allApps => allApps.name === newApp.name)) {
                enqueueSnackbar("Already exist", {
                    variant: "error"
                });
            } else {
                const res = await dispatch(createApp(newApp));
                dispatch(setSelectedApp(res.prod.app_sid));
                setPreferences(user.id, "selectedApp", res.prod.app_sid);
                enqueueSnackbar("App created", {
                    variant: "success"
                });
                handleClose(true);
                analytics.logEvent(analytics.CREATE_APP_CREATED, { name: name });
            }
        } catch (error) {
            enqueueSnackbar(error.message, {
                variant: "error"
            });
            analytics.logEvent(analytics.CREATE_APP_CREATE_ERROR, { name: name });
        }
    };

    const handleNameChange = event => {
        setName(event.target.value);
    };

    const handleTypeChange = event => {
        setType(event.target.value);
    };

    const handleBlur = feild => event => {
        setTouched({ ...touched, [feild]: true });
    };

    function validate(name, type) {
        // const reg = /^\S+$/;
        // if (touched.name && !reg.test(name)) {
        //     errors.name = "Name should contain lowercase letters only and without spaces";
        // }
        if (touched.name && name && name.trim() === "") {
            errors.name = "Name can't be left empty";
        }

        if (touched.type && type && type.trim() === "") {
            errors.type = "Type can't be left empty";
        }

        if (name === "" || type === "") {
            setIsValidated(false);
        } else {
            setIsValidated(true);
        }

        return errors;
    }

    return (
        <Dialog
            open={open}
            fullWidth={true}
            maxWidth={"sm"}
            onClose={() => handleClose(false)}
            keepMounted
            aria-labelledby="form-dialog-title"
        >
            <DialogTitle id="form-dialog-title">
                <Typography
                    component="span"
                    variant="h4"
                    className={classes.inline}
                    color="textPrimary"
                >
                    {"Create a new App"}
                </Typography>
            </DialogTitle>
            <DialogContent dividers={true}>
                <Box>
                    <TextField
                        autoFocus
                        id="name"
                        value={name}
                        required
                        onChange={handleNameChange}
                        variant="outlined"
                        label="App Name"
                        fullWidth
                        helperText={"Name should be unique"}
                        error={Boolean(touched.name && errors.name)}
                        onBlur={handleBlur("name")}
                    />
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => handleClose(false)} color="secondary">
                    Cancel
                </Button>
                <Button
                    onClick={handleAddApp}
                    disabled={!isValidated}
                    color="secondary"
                    variant="contained"
                >
                    Create
                </Button>
            </DialogActions>
        </Dialog>
    );
}

AddAccountAppDialog.propTypes = {
    className: PropTypes.string
};

export default AddAccountAppDialog;
