/* eslint-disable no-param-reassign */
import produce from "immer";
import {
    GET_MEMBERS_REQUEST,
    GET_MEMBERS_SUCCESS,
    GET_MEMBERS_FAILURE
} from "../events/membersEvents";

const initialState = {
    members: []
};

const membersReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_MEMBERS_REQUEST: {
            return produce(state, draft => {
                if (!state.members.length) {
                    draft.members = [];
                }
            });
        }

        case GET_MEMBERS_SUCCESS: {
            const { members } = action.payload;
            return produce(state, draft => {
                draft.members = members;
            });
        }

        case GET_MEMBERS_FAILURE: {
            return produce(state, draft => {
                // Maybe store error
                draft.members = [];
            });
        }

        default: {
            return state;
        }
    }
};

export default membersReducer;
