/* eslint-disable no-param-reassign */
import produce from "immer";
import {
    LOGIN_REQUEST,
    LOGIN_SUCCESS,
    LOGIN_FAILURE,
    REGISTER_URL,
    LOGOUT,
    AUTH_REFRESH,
    CREATE_APP_SUCCESS,
    GET_USER_REQUEST,
    GET_USER_SUCCESS,
    GET_ACCOUNT_REQUEST,
    GET_ACCOUNT_SUCCESS,
    GET_APPS_REQUEST,
    GET_APPS_SUCCESS,
    GET_APPS_FAILURE,
    GET_SELECTED_APP_REQUEST,
    SET_SELECTED_APP_SUCCESS,
    DELETE_APP_SUCCESS,
    UPDATE_SELECTED_APP_NAME_SUCCESS,
    UPDATE_SELECTED_APP_SUCCESS
} from "../events/userEvents";
import {
    getUserCredentials,
    storeUserCredentials,
    clearUserCredentials,
    storeAccessToken,
    storeUser,
    getUser,
    storeAccount,
    getAccount,
    getApps,
    storeApps,
    clearApps,
    getSelectedApp,
    storeSelectedAppId
} from "../utils/credentials";

import {
    ADD_PROVIDER_SUCCESS,
    ADD_RULE_SUCCESS,
    UPDATE_PROVIDER_SUCCESS,
    UPDATE_RULE_SUCCESS
} from "../events/providerEvents";

const initialState = getUserCredentials();

const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOGIN_REQUEST: {
            return produce(state, draft => {
                // Ensure we clear current session
                draft.account = null;
                draft.user = null;
                draft.token = null;
                draft.refreshToken = null;
                clearUserCredentials();
            });
        }

        case LOGIN_SUCCESS: {
            const { account, user, token, refreshToken } = action.payload;
            return produce(state, draft => {
                draft.account = account;
                draft.user = user;
                draft.token = token;
                draft.refreshToken = refreshToken;
                storeUserCredentials({
                    accessToken: token,
                    refreshToken: refreshToken,
                    account: account,
                    user: user
                });
            });
        }

        case LOGIN_FAILURE: {
            return produce(state, draft => {
                // Maybe store error
                draft.account = null;
                draft.user = null;
                draft.token = null;
                draft.refreshToken = null;
                draft.selectedApp = null;
                draft.apps = null;
                clearUserCredentials();
            });
        }

        case LOGOUT: {
            return produce(state, draft => {
                draft.account = null;
                draft.user = null;
                draft.token = null;
                draft.refreshToken = null;
                draft.selectedApp = null;
                draft.apps = null;
                clearUserCredentials();
            });
        }

        case AUTH_REFRESH: {
            const { token } = action.payload;
            return produce(state, draft => {
                draft.token = token;
                storeAccessToken(token);
            });
        }

        case GET_USER_REQUEST: {
            return produce(state, draft => {
                draft.user = getUser();
            });
        }

        case GET_USER_SUCCESS: {
            const { user } = action.payload;
            return produce(state, draft => {
                draft.user = user;
                storeUser(user);
            });
        }

        case GET_ACCOUNT_REQUEST: {
            return produce(state, draft => {
                draft.account = getAccount();
            });
        }

        case GET_ACCOUNT_SUCCESS: {
            const { account } = action.payload;
            return produce(state, draft => {
                draft.account = account;
                storeAccount(account);
            });
        }

        case GET_APPS_REQUEST: {
            return produce(state, draft => {
                draft.apps = getApps();
            });
        }

        case GET_APPS_SUCCESS: {
            const { apps } = action.payload;
            return produce(state, draft => {
                draft.apps = apps;
                storeApps(apps);
                // if (apps != null && apps.length > 0) {
                //     if (!getSelectedApp()) {
                //         storeSelectedAppId(apps[0].app_sid);
                //     }
                // }
            });
        }

        case CREATE_APP_SUCCESS: {
            const { app } = action.payload;
            return produce(state, draft => {
                const apps = [...draft.apps, app.dev, app.prod];
                draft.apps = apps;
                storeApps(apps);
            });
        }

        case DELETE_APP_SUCCESS: {
            const { appName } = action.payload;
            return produce(state, draft => {
                const apps = draft.apps.filter(app => app.name !== appName);
                draft.apps = apps;
                storeApps(apps);
                draft.selectedApp = apps[0];
                storeSelectedAppId(apps[0].app_sid);
            });
        }

        case GET_APPS_FAILURE: {
            return produce(state, draft => {
                draft.apps = null;
                clearApps();
            });
        }

        case GET_SELECTED_APP_REQUEST: {
            return produce(state, draft => {
                draft.selectedApp = getSelectedApp();
            });
        }

        case SET_SELECTED_APP_SUCCESS: {
            const { selectedApp } = action.payload;
            return produce(state, draft => {
                draft.selectedApp = selectedApp;
                storeSelectedAppId(selectedApp.app_sid);
            });
        }

        case UPDATE_SELECTED_APP_NAME_SUCCESS: {
            const { appName, updatedAppName } = action.payload;
            return produce(state, draft => {
                const selectedApp = draft.selectedApp;
                draft.selectedApp = { ...selectedApp, name: updatedAppName };
                const apps = draft.apps.map(a => {
                    if (a.name === appName) {
                        return { ...a, name: updatedAppName };
                    }
                    return a;
                });
                draft.apps = apps;
                storeApps(apps);
            });
        }

        case UPDATE_SELECTED_APP_SUCCESS: {
            const { app } = action.payload;
            return produce(state, draft => {
                draft.selectedApp = app;
                const apps = draft.apps.map(a => {
                    if (a.app_sid === app.app_sid) {
                        return app;
                    }
                    return a;
                });
                draft.apps = apps;
                storeApps(apps);
            });
        }

        case UPDATE_PROVIDER_SUCCESS: {
            const provider = action.payload.provider;
            return produce(state, draft => {
                let tempProviders = draft.selectedApp.providers;
                tempProviders = tempProviders.map(p => {
                    if (p.provider_sid === provider.provider_sid && p.config) {
                        p.config = provider.config;
                    }
                    return p;
                });
                // draft.selectedApp.providers = tempProviders;
                let tempApps = draft.apps;
                tempApps = tempApps.map(a => {
                    if (a.app_sid === draft.selectedApp.app_sid) {
                        a.providers = tempProviders;
                    }
                    return a;
                });
                draft.apps = tempApps;
                storeApps(tempApps);
            });
        }

        case ADD_PROVIDER_SUCCESS: {
            const provider = action.payload.provider;
            return produce(state, draft => {
                let tempProviders = [provider, ...draft.selectedApp.providers];
                draft.selectedApp.providers = tempProviders;
                let tempApps = draft.apps;
                tempApps = tempApps.map(a => {
                    if (a.app_sid === draft.selectedApp.app_sid) {
                        a.providers = tempProviders;
                    }
                    return a;
                });
                draft.apps = tempApps;
                storeApps(tempApps);
            });
        }

        case UPDATE_RULE_SUCCESS: {
            const rule = action.payload.rule;
            return produce(state, draft => {
                let tempDeliveryRules = draft.selectedApp.delivery_rules;
                tempDeliveryRules = tempDeliveryRules.map(r => {
                    if (r.rule_sid === rule.rule_sid && r.rule) {
                        r.rule = rule.rule;
                    }
                    return r;
                });
                let tempApps = draft.apps;
                tempApps = tempApps.map(a => {
                    if (a.app_sid === draft.selectedApp.app_sid) {
                        a.delivery_rules = tempDeliveryRules;
                    }
                    return a;
                });
                draft.apps = tempApps;
                storeApps(tempApps);
            });
        }

        case ADD_RULE_SUCCESS: {
            const rule = action.payload.rule;
            return produce(state, draft => {
                let tempDeliveryRules = [rule, ...draft.selectedApp.delivery_rules];
                draft.selectedApp.delivery_rules = tempDeliveryRules;
                let tempApps = draft.apps;
                tempApps = tempApps.map(a => {
                    if (a.app_sid === draft.selectedApp.app_sid) {
                        a.delivery_rules = tempDeliveryRules;
                    }
                    return a;
                });
                draft.apps = tempApps;
                storeApps(tempApps);
            });
        }

        default: {
            return state;
        }
    }
};

export default userReducer;
