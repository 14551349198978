import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import "src/assets/css/devices.min.css";
import "react-perfect-scrollbar/dist/css/styles.css";
import "nprogress/nprogress.css";
import "src/mixins/chartjs";
import { enableES5 } from "immer";
import { colors } from "@material-ui/core";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import * as serviceWorker from "src/serviceWorker";
import { SettingsProvider } from "src/context/SettingsContext";
import { configureStore } from "src/store";
import { restoreSettings } from "src/core/utils/settings";
import App from "src/App";

enableES5();

const store = configureStore();
const settings = restoreSettings();

require("dotenv").config();

ReactDOM.render(
    <Provider store={store}>
        <SettingsProvider settings={settings}>
            <App />
        </SettingsProvider>
    </Provider>,
    document.getElementById("root")
);

serviceWorker.register();
