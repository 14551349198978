import React from "react";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import { GET_STARTED_PATH } from "src/Routes";

function AuthGuard({ children }) {
    const account = useSelector(state => state.account);

    if (!account.user) {
        return <Redirect to={GET_STARTED_PATH} />;
    }

    return children;
}

AuthGuard.propTypes = {
    children: PropTypes.any
};

export default AuthGuard;
