export const UPDATE_EVENT_SUCCESS = "@template/update-event-success";
export const UPDATE_EVENT_CHANNELS_SUCCESS = "@template/update-event-channels-success";
export const UPDATE_EVENT_TEMPLATES_SUCCESS = "@template/update-event-templates-success";
export const UPDATE_EVENT_FAILURE = "@template/update-event-failure";

export const CREATE_EVENT_SUCCESS = "@template/create-event-success";
export const CREATE_EVENT_FAILURE = "@template/create-event-failure";

export const GET_EVENTS_REQUEST = "@template/get-events-request";
export const GET_EVENTS_SUCCESS = "@template/get-events-success";
export const GET_EVENTS_FAILURE = "@template/get-events-failure";

export const ARCHIVE_EVENT_REQUEST = "@template/archive-event-request";
export const ARCHIVE_EVENT_SUCCESS = "@template/archive-event-success";
export const ARCHIVE_EVENT_FAILURE = "@template/archive-event-failure";

export const GET_EVENT_BY_ID_SUCCESS = "@template/get-event-id-success";
export const GET_EVENT_BY_ID_FAILURE = "@template/get-events-id-failure";

export const GET_EVENTS_LOGS_REQUEST = "@template/get-events-logs-request";
export const GET_EVENTS_LOGS_SUCCESS = "@template/get-events-logs-success";
export const GET_EVENTS_LOGS_FAILURE = "@template/get-events-logs-failure";

export const EXPORT_EVENTS_LOGS_REQUEST = "@template/export-events-logs-request";
export const EXPORT_EVENTS_LOGS_SUCCESS = "@template/export-events-logs-success";
export const EXPORT_EVENTS_LOGS_FAILURE = "@template/export-events-logs-failure";

export const COPY_EVENT_PROD_SUCCESS = "@template/copy-event-prod-success"

export function createEventSuccess(event) {
    return {
        type: CREATE_EVENT_SUCCESS,
        payload: {
            event
        }
    };
}

export function createEventFailure() {
    return {
        type: CREATE_EVENT_FAILURE
    };
}

export function updateEventSuccess(event) {
    return {
        type: UPDATE_EVENT_SUCCESS,
        payload: {
            event
        }
    };
}

export function updateEventChannelsSuccess(eventId, channels) {
    return {
        type: UPDATE_EVENT_CHANNELS_SUCCESS,
        payload: {
            eventId,
            channels
        }
    };
}

export function updateEventTemplatesSuccess(eventId, templates) {
    return {
        type: UPDATE_EVENT_TEMPLATES_SUCCESS,
        payload: {
            eventId,
            templates
        }
    };
}

export function updateEventFailure() {
    return {
        type: UPDATE_EVENT_FAILURE
    };
}

export function getEventsRequest() {
    return {
        type: GET_EVENTS_REQUEST
    };
}

export function getEventsSuccess(events) {
    return {
        type: GET_EVENTS_SUCCESS,
        payload: {
            events
        }
    };
}

export function getEventsFailure() {
    return {
        type: GET_EVENTS_FAILURE
    };
}

export function getEventByIdSuccess(event) {
    return {
        type: GET_EVENT_BY_ID_SUCCESS,
        payload: {
            event
        }
    };
}

export function getEventByIdFailure() {
    return {
        type: GET_EVENT_BY_ID_FAILURE
    };
}

export function getEventsLogsRequest() {
    return {
        type: GET_EVENTS_LOGS_REQUEST
    };
}

export function getEventsLogsSuccess(logs, appId, lastRequestId) {
    return {
        type: GET_EVENTS_LOGS_SUCCESS,
        payload: {
            logs,
            appId,
            lastRequestId
        }
    };
}

export function getEventsLogsFailure() {
    return {
        type: GET_EVENTS_LOGS_FAILURE
    };
}

export function exportEventsLogsRequest() {
    return {
        type: EXPORT_EVENTS_LOGS_REQUEST
    };
}

export function exportEventsLogsSuccess(logs, appId, lastRequestId) {
    return {
        type: EXPORT_EVENTS_LOGS_SUCCESS,
        payload: {
            logs,
            appId,
            lastRequestId
        }
    };
}

export function exportEventsLogsFailure() {
    return {
        type: EXPORT_EVENTS_LOGS_FAILURE
    };
}

export function archiveEventRequest() {
    return {
        type: ARCHIVE_EVENT_REQUEST
    };
}

export function archiveEventSuccess(eventId) {
    return {
        type: ARCHIVE_EVENT_SUCCESS,
        payload: {
            eventId
        }
    };
}
export function archiveEventFailure() {
    return {
        type: ARCHIVE_EVENT_FAILURE
    };
}


export function copyEventProdSuccess(eventId) {
    return {
        type: COPY_EVENT_PROD_SUCCESS,
        payload: {
            eventId
        }
    };
}