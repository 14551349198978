import * as firebase from "firebase/app";
import "@firebase/analytics";
import "@firebase/messaging";
import React from "react";
import { Helmet } from "react-helmet";

export const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

export var analytics = null;
firebase.analytics.isSupported().then(isSupported => {
    if (isSupported) {
        analytics = firebase.analytics();
    }
});

const messaging = firebase.messaging.isSupported() ? firebase.messaging() : null;

if (messaging != null) {
    messaging.onMessage(function(payload) {
        displayNotification(payload);
    });
}

export async function getFirebaseToken(setToken, setLoading, showError, browserError) {
    try {
        setLoading(true);
        await messaging.requestPermission();
        const token = await messaging.getToken();
        console.log("FCM Token: ", token);
        setToken(token);
        setLoading(false);
    } catch (err) {
        console.log("Error Ocurred: ", err);
        setLoading(false);
        if (messaging == null) {
            browserError();
        } else {
            showError();
        }
        return null;
    }
}

function displayNotification(payload) {
    if (!("Notification" in window)) {
        console.log("This browser does not support desktop notification");
    } else if (Notification.permission == "granted") {
        navigator.serviceWorker.getRegistration().then(function(reg) {
            var options = {
                body: payload.data.body
            };
            console.log("onMessage", payload);
            reg.showNotification(payload.data.title, options);
        });
    }
}

function FCM() {
    return <Helmet></Helmet>;
}

export default FCM;
