import userService from "../api/userService";
import {
    loginRequest,
    loginSuccess,
    loginFailure,
    logout,
    getAccountRequest,
    getAccountSuccess,
    getUserRequest,
    getUserSuccess,
    getAppsRequest,
    getAppsSuccess,
    getAppsFailure,
    getSelectedAppRequest,
    createAppSuccess,
    inviteUserSuccess,
    roleChangeSuccess,
    reInviteUserSuccess,
    deleteInviteUserSuccess,
    deleteMemberSuccess,
    setSelectedAppSuccess,
    deleteAppSuccess,
    updateSelectedAppNameSuccess,
    updateSelectedAppSuccess
} from "../events/userEvents";

export function login(email, password) {
    return async dispatch => {
        try {
            dispatch(loginRequest());
            const response = await userService.loginWithEmailAndPassword(email, password);
            //check for mfa
            if (response["mfa_required"]) {
                return response;
            } else {
                dispatch(
                    loginSuccess(
                        response.account,
                        response.user,
                        response.auth_credentials.access_token,
                        response.auth_credentials.refresh_token
                    )
                );
                return response;
            }
        } catch (error) {
            dispatch(loginFailure());
            throw error;
        }
    };
}

export function userExists(email) {
    return async dispatch => {
        try {
            const response = await userService.userExists(email);
            return response;
        } catch (error) {
            throw error;
        }
    };
}

export function loginWithGoogle(token) {
    //console.log(token);
    return async dispatch => {
        try {
            dispatch(loginRequest());
            const response = await userService.loginWithGoogle(token);
            //check for mfa
            if (response["mfa_required"]) {
                return response;
            } else {
                dispatch(
                    loginSuccess(
                        response.account,
                        response.user,
                        response.auth_credentials.access_token,
                        response.auth_credentials.refresh_token
                    )
                );
                return response;
            }
        } catch (error) {
            dispatch(loginFailure());
            throw error;
        }
    };
}

export function verifyOtp(token, otp) {
    return async dispatch => {
        try {
            const response = await userService.verifyOtp(token, otp);
            dispatch(
                loginSuccess(
                    response.account,
                    response.user,
                    response.auth_credentials.access_token,
                    response.auth_credentials.refresh_token
                )
            );
        } catch (error) {
            dispatch(loginFailure());
            throw error;
        }
    };
}

export function resendOtp(token) {
    return async dispatch => {
        try {
            await userService.resendOtp(token);
        } catch (error) {
            dispatch(loginFailure());
            throw error;
        }
    };
}

export function getUserAndAccount() {
    return async dispatch => {
        try {
            dispatch(getUserRequest());
            const response = await userService.getUser();

            dispatch(getAccountRequest());
            const accResponse = await userService.getAccount();

            dispatch(getUserSuccess(response));
            dispatch(getAccountSuccess(accResponse));
        } catch (error) {
            throw error;
        }
    };
}

export function updateAccount(account) {
    return async dispatch => {
        try {
            const response = await userService.updateAccount(account);
            dispatch(getAccountSuccess(response));
        } catch (error) {
            throw error;
        }
    };
}

export function updateAccountImage(image) {
    return async dispatch => {
        try {
            const response = await userService.updateAccountImage(image);
            dispatch(getAccountSuccess(response));
        } catch (error) {
            throw error;
        }
    };
}

export function updateUser(user) {
    return async dispatch => {
        try {
            const response = await userService.updateUser(user);
            dispatch(getUserSuccess(response));
        } catch (error) {
            dispatch(getUserRequest());
            throw error;
        }
    };
}

export function registerUser(user) {
    return async dispatch => {
        try {
            const response = await userService.registerUser(user);
            dispatch(
                loginSuccess(
                    response.account,
                    response.user,
                    response.auth_credentials.access_token,
                    response.auth_credentials.refresh_token
                )
            );
            return response;
        } catch (error) {
            dispatch(getUserRequest());
            throw error;
        }
    };
}

export function registerWithGoogle(accountName, token) {
    return async dispatch => {
        try {
            const response = await userService.registerWithGoogle(accountName, token);
            dispatch(
                loginSuccess(
                    response.account,
                    response.user,
                    response.auth_credentials.access_token,
                    response.auth_credentials.refresh_token
                )
            );
            return response;
        } catch (error) {
            dispatch(getUserRequest());
            throw error;
        }
    };
}

export function getApps() {
    return async dispatch => {
        try {
            dispatch(getAppsRequest());
            const response = await userService.getApps();
            await dispatch(getAppsSuccess(response.apps));
        } catch (error) {
            dispatch(getAppsFailure());
            throw error;
        }
    };
}

export function getSelectedApp() {
    return async dispatch => {
        try {
            dispatch(getSelectedAppRequest());
        } catch (error) {
            throw error;
        }
    };
}

export function setSelectedApp(app) {
    return async dispatch => {
        try {
            dispatch(setSelectedAppSuccess(app));
        } catch (error) {
            throw error;
        }
    };
}

export function updateSelectedApp(app) {
    return async dispatch => {
        try {
            await userService.updateApp(app);
            dispatch(updateSelectedAppSuccess(app));
        } catch (error) {
            throw error;
        }
    };
}

export function updateSelectedAppName(appName, updatedAppName) {
    return async dispatch => {
        try {
            await userService.updateAppName(appName, updatedAppName);
            dispatch(updateSelectedAppNameSuccess(appName, updatedAppName));
        } catch (error) {
            throw error;
        }
    };
}

export function deleteSelectedApp(app_name) {
    return async dispatch => {
        try {
            await userService.deleteApp(app_name);
            dispatch(deleteAppSuccess(app_name));
        } catch (error) {
            throw error;
        }
    };
}

export function updateProvidersSelectedApp(app, isEdit) {
    return async dispatch => {
        try {
            await userService.updateApp(app);
        } catch (error) {
            throw error;
        }
    };
}

export function createApp(app) {
    return async dispatch => {
        try {
            const response = await userService.createApp(app);
            dispatch(createAppSuccess(response));
            return response;
        } catch (error) {
            throw error;
        }
    };
}

export function inviteUser(user) {
    return async dispatch => {
        try {
            const response = await userService.inviteUser(user);
            dispatch(inviteUserSuccess(response));
        } catch (error) {
            throw error;
        }
    };
}

export function reInviteUser(userId) {
    return async dispatch => {
        try {
            const response = await userService.reInviteUser(userId);
            dispatch(reInviteUserSuccess(response));
        } catch (error) {
            throw error;
        }
    };
}

export function deleteInvite(userId) {
    return async dispatch => {
        try {
            const response = await userService.deleteInvite(userId);
            dispatch(deleteInviteUserSuccess(response));
        } catch (error) {
            throw error;
        }
    };
}

export function deleteMember(userId) {
    return async dispatch => {
        try {
            const response = await userService.deleteMember(userId);
            dispatch(deleteMemberSuccess(response));
        } catch (error) {
            throw error;
        }
    };
}

export function changeRole(newRole) {
    return async dispatch => {
        try {
            const response = await userService.changeRole(newRole);
            dispatch(deleteInviteUserSuccess(response));
        } catch (error) {
            throw error;
        }
    };
}

export function changePwd(newPwd) {
    return async dispatch => {
        try {
            await userService.changePwd(newPwd);
        } catch (error) {
            throw error;
        }
    };
}

export function joinAccount(member) {
    return async dispatch => {
        try {
            const response = await userService.joinAccount(member);
            dispatch(
                loginSuccess(
                    response.account,
                    response.user,
                    response.auth_credentials.access_token,
                    response.auth_credentials.refresh_token
                )
            );
            return response;
        } catch (error) {
            throw error;
        }
    };
}

export function joinAccountWithGoogle(googleIdToken, token) {
    return async dispatch => {
        try {
            const response = await userService.joinAccountWithGoogle(googleIdToken, token);
            dispatch(
                loginSuccess(
                    response.account,
                    response.user,
                    response.auth_credentials.access_token,
                    response.auth_credentials.refresh_token
                )
            );
            return response;
        } catch (error) {
            throw error;
        }
    };
}

export function joinOrganizationWithGoogle(accountSid, token) {
    return async dispatch => {
        try {
            const response = await userService.joinOrgWithGoogle(accountSid, token);
            dispatch(
                loginSuccess(
                    response.account,
                    response.user,
                    response.auth_credentials.access_token,
                    response.auth_credentials.refresh_token
                )
            );
        } catch (error) {
            throw error;
        }
    };
}

export function forgotPwd(email) {
    return async dispatch => {
        try {
            await userService.forgotPwd(email);
        } catch (error) {
            throw error;
        }
    };
}

export function getMembers(setMembers) {
    return async dispatch => {
        try {
            await userService.getMembers(setMembers);
        } catch (error) {
            throw error;
        }
    };
}

export function sendVerificationEmail(email) {
    return async dispatch => {
        try {
            await userService.sendVerificationEmail(email);
        } catch (error) {
            throw error;
        }
    };
}

export function verifyEmail(token) {
    return async dispatch => {
        try {
            await userService.verifyEmail(token);
        } catch (error) {
            throw error;
        }
    };
}

export function userlogout() {
    return async dispatch => {
        userService.logout();
        dispatch(logout());
    };
}

export async function createCheckout(priceId) {
    const resp = await userService.createCheckout(priceId);
    return resp["data"]["url"];
}

export async function createCustomerPortal() {
    const resp = await userService.createCustomerPortal();
    return resp["data"]["url"];
}

export async function createCustomer(customerObj) {
    const resp = await userService.createCustomer(customerObj);
    return resp["data"];
}

export async function getCustomer() {
    const resp = await userService.getCustomer();
    return resp["data"];
}

export async function updateCustomer(customerObj) {
    const resp = await userService.updateCustomer(customerObj);
    return resp["data"];
}

export async function createSubscription(paymentMethodId) {
    const resp = await userService.createSubscription(paymentMethodId);
    return resp["data"];
}

export async function getUsageMetrics() {
    const resp = await userService.getUsageMetrics();
    return resp["data"];
}

export async function getBillingPlan() {
    const resp = await userService.getBillingPlan();
    return resp["data"];
}
