/* eslint-disable no-use-before-define */
/* eslint-disable react/prop-types */
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import FileBase64 from "react-file-base64";
import {
    makeStyles,
    useTheme,
    Dialog,
    DialogTitle,
    DialogActions,
    Button,
    DialogContent,
    Typography,
    IconButton,
    Box,
    TextField,
    CircularProgress,
    ListItemSecondaryAction
} from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Close from "@material-ui/icons/Close";
import { useSnackbar } from "notistack";
import { sendContactUsEmail } from "src/core/repo/sendMessageRepo";
import { ChevronRight, Description, Help } from "@material-ui/icons";
import { Slack } from "react-feather";

const useStyles = makeStyles(theme => ({
    root: {
        width: "100%",
        backgroundColor: theme.palette.background.paper
    }
}));

const appId = process.env.REACT_APP_RAVEN_APP_ID;
const apiKey = process.env.REACT_APP_RAVEN_API_KEY;

function Contactus({ handleCloseContectUsDialog, handleClose }) {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const theme = useTheme();
    const dispatch = useDispatch();
    const [message, setMessage] = useState("");
    const [fileName, setFileName] = useState("");
    const [encodedFile, setEncodedFile] = useState("");
    const [loading, setLoading] = useState(false);

    const handleCloseDialogs = () => {
        handleCloseContectUsDialog();
        handleClose();
    };

    const handleMessageChange = event => {
        setMessage(event.target.value);
    };

    const getFiles = files => {
        setFileName(files[0].name);
        let fileContent = files[0].base64;
        let length = files[0].base64.length;
        let i = 0;
        let ind = 0;
        while (i < length) {
            if (fileContent[i] === ",") {
                ind = i;
                i = length;
            }
            i++;
        }
        fileContent = files[0].base64.substring(ind + 1);
        setEncodedFile(fileContent);
    };

    const handleSendMessage = async message => {
        setLoading(true);
        try {
            // Make API request
            let payload = {};
            payload.event = "contact_us";
            payload.user = {};
            payload.user.email = "support@ravenapp.dev";
            payload.data = {};
            payload.data.message = message;
            payload.data.attachment_1 = encodedFile;
            payload.data.attachment_name_1 = fileName;
            await dispatch(sendContactUsEmail(appId, payload, apiKey));
            enqueueSnackbar("Mail Sent", {
                variant: "success"
            });
            // analytics.logEvent(analytics.INVITE_USER_CREATED, {
            //     email: values.email
            // });
        } catch (error) {
            enqueueSnackbar(error.message, {
                variant: "error"
            });
            // analytics.logEvent(analytics.INVITE_USER_CREATE_ERROR, {
            //     email: values.email
            // });
        }
        setLoading(false);
    };

    return (
        <Dialog
            fullWidth
            maxWidth="sm"
            aria-labelledby="confirmation-dialog-title"
            open={true}
            onClose={handleCloseDialogs}
        >
            <DialogTitle disableTypography>
                <Box display="flex" alignItems="center" justifyContent="space-between" mb={1}>
                    <Typography variant="h4">Contact Support</Typography>
                    <IconButton onClick={handleCloseDialogs} size="small">
                        <Close fontSize="small" />
                    </IconButton>
                </Box>
                <Typography variant="body2" color="textSecondary">
                    What is the issue? If you're reporting a bug, what are the steps you took so we
                    can reproduce the behaviour?
                </Typography>
            </DialogTitle>
            <DialogContent dividers style={{ padding: 0 }}>
                <Box ml={3} mr={3} mt={1}>
                    <TextField
                        variant="standard"
                        InputProps={{
                            disableUnderline: true
                        }}
                        style={{ width: "100%" }}
                        multiline
                        rows={10}
                        value={message}
                        onChange={handleMessageChange}
                        placeholder="E.g. I get an error when I try to add XYZ integration (attached). I removed and then added again, but still get the error."
                    />
                    <Box mb={1}>
                        {/* <FileBase64 multiple={true} onDone={getFiles} /> */}
                    </Box>
                </Box>
            </DialogContent>
            <DialogActions style={{ justifyContent: "space-between" }}>
                <Typography variant="body2" color="textSecondary" style={{ marginLeft: 10 }}>
                    You can also email us at <b>support@ravenapp.dev</b>
                </Typography>
                <Button
                    onClick={() => handleSendMessage(message)}
                    color="secondary"
                    variant="contained"
                //disabled={uploadingMembers.length === 0}
                >
                    Send message
                </Button>
            </DialogActions>
            {loading && (
                <div style={{ position: "absolute", left: "45%", top: "45%" }}>
                    <CircularProgress />
                </div>
            )}
        </Dialog>
    );
}

Contactus.propTypes = {};

export default Contactus;
