import React, { useEffect, useState } from "react";
import {
    Box,
    Container,
    Divider,
    Tab,
    Tabs,
    Grid,
    Button,
    SvgIcon,
    makeStyles,
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    AccordionActions,
    TextField,
    Radio,
    useTheme,
    Tooltip,
    IconButton
} from "@material-ui/core";
import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";
import Page from "src/components/Page";
import { getApps } from "src/core/repo/userRepo";
import useIsMountedRef from "src/hooks/useIsMountedRef";
import * as analytics from "src/core/components/analytics";
import * as utils from "src/core/utils/utils";

import { X, Plus as PlusIcon, HelpCircle } from "react-feather";
import AddAccountAppDialog from "./AddAccountAppDialog";
import ExpandMore from "@material-ui/icons/ExpandMore";
import { Card } from "@material-ui/core";
import { CardHeader } from "@material-ui/core";
import { CardContent } from "@material-ui/core";
import { CardActions } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    root: {
        minHeight: "100%",
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3)
    }
}));

export const allAppTypes = ["development", "staging", "production"];

export function Environment({ selectedApp, onAppUpdate, ...rest }) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const theme = useTheme();
    const isMountedRef = useIsMountedRef();
    const [appType, setAppType] = React.useState(
        selectedApp.type ? selectedApp.type : allAppTypes[0]
    );

    const handleChange = e => {
        setAppType(e.target.value);
    };

    useEffect(() => {
        setAppType(selectedApp.type ? selectedApp.type : allAppTypes[0]);
    }, [selectedApp]);

    const handleSave = () => {
        onAppUpdate(
            {
                ...selectedApp,
                type: appType
            },
            "Environment updated successfully",
            "Error updating environment"
        );
    };

    var radioDisabled = selectedApp["is_default"];

    return (
        <>
            <Card>
                <CardHeader
                    style={{ paddingTop: 20, paddingBottom: 15 }}
                    title={
                        <Box display="flex" alignItems="center">
                            <Typography variant="h5">Environment</Typography>
                            <Box mr={1} />
                            <Tooltip
                                title={
                                    "There are no restrictions on features/usage for any environment. Environment is purely used to differentiate how the app is being used."
                                }
                            >
                                <IconButton size="small">
                                    <HelpCircle size="16" color={theme.palette.text.secondary} />
                                </IconButton>
                            </Tooltip>
                        </Box>
                    }
                />
                <CardContent>
                    <Box width="100%" display="flex" flexDirection="column">
                        <Box
                            mb={2}
                            p={2}
                            border={appType === allAppTypes[0] ? 2 : 1}
                            borderColor={
                                appType === allAppTypes[0] ? theme.palette.primary.main : "grey.300"
                            }
                            borderRadius={4}
                            display="flex"
                            alignItems="center"
                        >
                            <Radio
                                checked={appType === allAppTypes[0]}
                                onChange={handleChange}
                                value={allAppTypes[0]}
                                style={{ marginRight: 10 }}
                                name="radio-button-demo"
                                inputProps={{ "aria-label": "A" }}
                            />
                            <Box display="flex" flexDirection="column">
                                <Typography variant="body1" style={{ marginBottom: 5 }}>
                                    {utils.titleCase(allAppTypes[0])}
                                </Typography>
                                <Typography variant="body2" color="textSecondary">
                                    A working environment to play around with the platform.
                                </Typography>
                            </Box>
                        </Box>
                        <>
                            <Box
                                mb={2}
                                p={2}
                                border={appType === allAppTypes[1] ? 2 : 1}
                                borderColor={
                                    appType === allAppTypes[1]
                                        ? theme.palette.primary.main
                                        : "grey.300"
                                }
                                borderRadius={4}
                                display="flex"
                                alignItems="center"
                            >
                                <Radio
                                    checked={appType === allAppTypes[1]}
                                    onChange={handleChange}
                                    value={allAppTypes[1]}
                                    style={{ marginRight: 10 }}
                                    name="radio-button-demo"
                                    inputProps={{ "aria-label": "A" }}
                                />
                                <Box display="flex" flexDirection="column">
                                    <Typography variant="body1" style={{ marginBottom: 5 }}>
                                        Staging
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary">
                                        Used for testing to test changes before releasing them to
                                        Production.
                                    </Typography>
                                </Box>
                            </Box>
                            <Box
                                mb={2}
                                p={2}
                                border={appType === allAppTypes[2] ? 2 : 1}
                                borderColor={
                                    appType === allAppTypes[2]
                                        ? theme.palette.primary.main
                                        : "grey.300"
                                }
                                borderRadius={4}
                                display="flex"
                                alignItems="center"
                            >
                                <Radio
                                    checked={appType === allAppTypes[2]}
                                    onChange={handleChange}
                                    value={allAppTypes[2]}
                                    style={{ marginRight: 10 }}
                                    name="radio-button-demo"
                                    inputProps={{ "aria-label": "A" }}
                                />
                                <Box display="flex" flexDirection="column">
                                    <Typography variant="body1" style={{ marginBottom: 5 }}>
                                        Production
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary">
                                        This is a live environment and should be treated carefully
                                        since it could affect your users.
                                    </Typography>
                                </Box>
                            </Box>
                        </>
                    </Box>
                </CardContent>
                <Divider />
                <CardActions>
                    <Box display="flex" width="100%" justifyContent="flex-end" p={1}>
                        <Button variant="outlined" color="secondary" onClick={handleSave}>
                            Save
                        </Button>
                    </Box>
                </CardActions>
            </Card>
        </>
    );
}

export default Environment;
