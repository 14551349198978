import React, { useRef } from "react";
import { Router } from "react-router-dom";
import { createBrowserHistory } from "history";
import { create } from "jss";
import rtl from "jss-rtl";
import MomentUtils from "@date-io/moment";
import { SnackbarProvider } from "notistack";
import {
    createStyles,
    jssPreset,
    makeStyles,
    StylesProvider,
    ThemeProvider
} from "@material-ui/core";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import Auth from "src/components/Auth";
import CookiesNotification from "src/components/CookiesNotification";
import SettingsNotification from "src/components/SettingsNotification";
import GoogleAnalytics from "src/components/GoogleAnalytics";
import SegmentAnalytics from "src/core/components/SegmentAnalytics";
import ScrollReset from "src/components/ScrollReset";
import useSettings from "src/hooks/useSettings";
import { createTheme } from "src/theme";
import Routes from "src/Routes";
import FCM from "./core/components/FCM";
import "rsuite/dist/rsuite.min.css";

import DateFnsUtils from "@date-io/date-fns";
const history = createBrowserHistory();
const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

const useStyles = makeStyles(() =>
    createStyles({
        "@global": {
            "*": {
                boxSizing: "border-box",
                margin: 0,
                padding: 0
            },
            html: {
                "-webkit-font-smoothing": "antialiased",
                "-moz-osx-font-smoothing": "grayscale",
                height: "100%",
                width: "100%"
            },
            body: {
                height: "100%",
                width: "100%"
            },
            "#root": {
                height: "100%",
                width: "100%"
            }
        }
    })
);

function App() {
    useStyles();
    const { settings } = useSettings();
    let snackbarRef = null;
    return (
        <ThemeProvider theme={createTheme(settings)}>
            <StylesProvider jss={jss}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <SnackbarProvider
                        maxSnack={1}
                        ref={ele => {
                            snackbarRef = ele;
                        }}
                        onClose={(event, reason, key) => {
                            if (reason === "clickaway") {
                                snackbarRef.closeSnackbar(key);
                            }
                        }}
                        autoHideDuration={2000}
                    >
                        <Router history={history}>
                            <Auth>
                                <ScrollReset />
                                <SegmentAnalytics />
                                <GoogleAnalytics />
                                <FCM />
                                <CookiesNotification />
                                <SettingsNotification />
                                <Routes />
                            </Auth>
                        </Router>
                    </SnackbarProvider>
                </MuiPickersUtilsProvider>
            </StylesProvider>
        </ThemeProvider>
    );
}

export default App;
