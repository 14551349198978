
export function getActionName(actionType) {
    let act = actionType.split("/");
    if (act == null || act.length < 2) {
        return null;
    }
    return act[1].split("-").slice(0, -1).join("-");
}


export function loadingReducer(state = {}, action){
    const { type } = action;
    const actionName = getActionName(type);
    if (!actionName) {
      return {
        ...state,
      }
    }
   
    return {
    ...state,
    [actionName]: type.endsWith("-request")
    };
   };

export default loadingReducer;
