import { API_BASE_URL } from "src/config/index";

//login
export const LOGIN_URL = API_BASE_URL + "/login";
export const VERIFY_OTP_URL = API_BASE_URL + "/verify_otp";
export const RESEND_OTP_URL = API_BASE_URL + "/resend_otp";
export const REGISTER_URL = API_BASE_URL + "/signup";
export const GET_USER_URL = API_BASE_URL + "/v1/accounts/users";
export const GET_ACCOUNT_URL = API_BASE_URL + "/v1/accounts/";
export const UPDATE_ACCOUNT_IMAGE_URL = API_BASE_URL + "/v1/accounts/upload-logo";
export const GET_APPS_URL = API_BASE_URL + "/v1/apps/";
export const CHANGE_PWD_URL = API_BASE_URL + "/password/change";
export const INVITE_USER_URL = API_BASE_URL + "/inviteUser";
export const REINVITE_USER_URL = API_BASE_URL + "/reinvite";
export const DELETE_INVITE_USER_URL = API_BASE_URL + "/v1/accounts/users/invite";
export const DELETE_MEMBER_URL = API_BASE_URL + "/v1/accounts/users/deleteMember";
export const CHANGE_ROLE_URL = API_BASE_URL + "/v1/accounts/users/changeRole";
export const GET_MEMBERS_URL = API_BASE_URL + "/v1/accounts/users/list";
export const JOIN_ACCOUNT_URL = API_BASE_URL + "/joinaccount";
export const USER_EXISTS_URL = API_BASE_URL + "/user_exists";
export const FORGOT_PWD_URL = API_BASE_URL + "/password/forgot";
export const VERIFY_EMAIL_URL = API_BASE_URL + "/verifyUser";
export const SEND_VERIFICATION_EMAIL_URL = API_BASE_URL + "/sendEmailVerification";
export const GET_ACCESS_URL = "https://ravenapp.dev/get_access";

//join organisation
export const DOMAIN_EXISTS_URL = API_BASE_URL + "/v1/domain/get_domain?email=%s";
export const JOIN_ORGANIZATION_OTP = API_BASE_URL + "/v1/domain/new_member";
export const JOIN_ORGANIZATION_OTP_RESEND = API_BASE_URL + "/v1/domain/new_member/resend_otp";
export const JOIN_ORGANIZATION_OTP_VERIFY = API_BASE_URL + "/v1/domain/new_member/verify_otp";

//extensions
export const GET_APP_EXTENSIONS_URL = API_BASE_URL + "/v1/apps/%s/extensions";
export const CREATE_APP_EXTENSIONS_URL = API_BASE_URL + "/v1/apps/%s/extensions";
export const UPDATE_APP_EXTENSIONS_URL = API_BASE_URL + "/v1/apps/%s/extensions/%s";
export const GET_APP_RAZORPAY_AUTH_URL = API_BASE_URL + "/v1/apps/%s/extensions/razorpay/authorize";

// token refesh
export const REFRESH_TOKEN_URL = API_BASE_URL + "/oauth/token?refresh_token=";

//members
export const GET_ALL_MEMBERS = API_BASE_URL + "/v1/accounts/users/list";

//templates
export const TEMPLATES = API_BASE_URL + "/v2/apps/%s/templates";
export const GET_TEMPLATES = API_BASE_URL + "/v1/apps/%s/templates";
export const GET_TEMPLATE_BY_NAME = API_BASE_URL + "/v1/apps/%s/templates/";
export const UPDATE_TEMPLATE = API_BASE_URL + "/templates/update/";
export const CREATE_TEMPLATE = API_BASE_URL + "/templates/create/";
export const VOICE_TEMPLATE_URL = API_BASE_URL + "/v1/apps/%s/voice";
export const COPY_TEMPLATE = API_BASE_URL + "/v2/apps/%s/templates/%s/copy-to-prod";
export const GET_TEMPLATE_TAGS = API_BASE_URL + "/v2/apps/%s/templates/tags";
//events
export const GET_ALL_EVENTS = API_BASE_URL + "/v2/apps/%s/events";
export const GET_EVENT_BY_ID = API_BASE_URL + "/v2/apps/%s/events/%s";
export const CREATE_EVENT = API_BASE_URL + "/v2/apps/%s/events";
export const UPDATE_EVENT = API_BASE_URL + "/v2/apps/%s/events/%s";
export const CREATE_EVENT_TEMPLATE = API_BASE_URL + "/v2/apps/%s/events/%s/templates";
export const CREATE_EVENT_PUBLISH = API_BASE_URL + "/v2/apps/%s/events/%s/publish";
export const UPDATE_EVENT_CONFIG = API_BASE_URL + "/v2/apps/%s/events/%s/config";
export const UPDATE_EVENT_CHANNEL_RULES = API_BASE_URL + "/v2/apps/%s/events/%s/channel_rules";
export const ARCHIVE_EVENT = API_BASE_URL + "/v2/apps/%s/events/%s";
export const TEST_EVENT = API_BASE_URL + "/v1/apps/%s/events/send";
export const COPY_EVENT_PROD = API_BASE_URL + "/v2/apps/%s/events/%s/copy-prod";

//logs
export const GET_EVENTS_LOGS = API_BASE_URL + "/v1/apps/%s/requests";
export const EXPORT_EVENTS_LOGS = API_BASE_URL + "/v1/apps/%s/requests/csv";

// app events
export const GET_MESSAGE_EVENTS = API_BASE_URL + "/v1/apps/%s/events/";
export const GET_METRICS = API_BASE_URL + "/v2/apps/%s/metrics";
export const GET_LATENCY_METRICS = API_BASE_URL + "/v2/apps/%s/latency_metrics";
export const UPDATE_APP = API_BASE_URL + "/v1/apps/%s";
export const UPDATE_APP_NAME = API_BASE_URL + "/v1/apps/update/%s";
export const DELETE_APP = API_BASE_URL + "/v1/apps";
export const USAGE_METRICS = API_BASE_URL + "/v2/usage_metrics";
export const BILLING_PLAN = API_BASE_URL + "/v1/billing/plan";

//app users
export const GET_APP_USERS = API_BASE_URL + "/v1/apps/%s/users/web/list";
export const UPDATE_APP_USER = API_BASE_URL + "/v1/apps/%s/users/%s/web";
export const CREATE_UPDATE_APP_USERS = API_BASE_URL + "/v1/apps/%s/users/web";
export const UPDATE_APP_USER_DEVICES = API_BASE_URL + "/v1/apps/%s/users/%s/devices/%s/web";
export const CREATE_APP_USER_DEVICES = API_BASE_URL + "/v1/apps/%s/users/%s/devices/web";

//provider
export const CREATE_UPDATE_PROVIDER = API_BASE_URL + "/v1/apps/%s/providers/";
export const GET_ALL_PROVIDERS = API_BASE_URL + "/v1/static/provider-config/";

export const CREATE_PROVIDER_RULE = API_BASE_URL + "/v1/apps/%s/delivery_rules";
export const UPDATE_PROVIDER_RULE = API_BASE_URL + "/v1/apps/%s/delivery_rules/%s";
export const GET_ALL_PROVIDERS_RULE = API_BASE_URL + "/v1/apps/%s/delivery_rules/";

// message service
export const SEND_MESSAGE = API_BASE_URL + "/v1/apps/%s/messages/send/web";
export const SEND_CONTACT_US_EMAIL = API_BASE_URL + "/v1/apps/%s/events/send";

//billing
export const CREATE_CHECKOUT = API_BASE_URL + "/v1/billing/checkout-session";
export const CUSTOMER_PORTAL = API_BASE_URL + "/v1/billing/customer-portal-session";
export const CREATE_CUSTOMER = API_BASE_URL + "/v1/billing/customer";
export const CREATE_SUBSCRIPTION = API_BASE_URL + "/v1/billing/subscription";

//SSR
export const SSR_EXPORT_HTML_URL = `http://localhost:3001`;

//add domain by owner
export const ADD_DOMAIN_URL = API_BASE_URL + "/v1/domain";
export const VERIFY_DOMAIN_EMAIL = API_BASE_URL + "/v1/domain/verify_otp";
export const RESEND_VERIFY_DOMAIN_OTP = API_BASE_URL + "/v1/domain/resend_otp";
export const DELETE_DOMAIN = API_BASE_URL + "/v1/domain?domain_name=%s";
//export default {}

export const SLACK_COMMUNITY_URL =
    "https://join.slack.com/t/ravenappdev/shared_invite/zt-13oroo66t-8TYKmmd1lgcWX_vrbAt7EQ";
export const RAVEN_DOCS_URL = "https://docs.ravenapp.dev";
