import * as firebase from "firebase/app";
import "@firebase/analytics";
import { analytics as firebaseAnalytics } from "./FCM";

export async function logEvent(name, props = null) {
    if (firebaseAnalytics != null) {
        firebaseAnalytics.logEvent(name, props);
    }
    await window.analytics.track(name, props);
}

export async function setUserId(userId, props = null) {
    if (firebaseAnalytics != null) {
        firebaseAnalytics.setUserId(userId);
    }
    await window.analytics.identify(userId, props);
}

export function setUserProperties(props) {
    if (firebaseAnalytics != null) {
        firebaseAnalytics.setUserProperties(props);
    }
}
export async function logGroup(id, props = null) {
    await window.analytics.group(id, props);
}
export const ONBOARDING_CLOSED = "Onboarding Closed";
export const ONBOARDING_STARTED = "Onboarding Started";
export const HOME_PAGE = "Home Page Viewed";
export const SIGNUP_PAGE = "Signup page Viewed";
export const LOGIN_PAGE = "Login Page Viewed";
export const JOIN_ACCOUNT_PAGE = "Join Account Page Viewed";
export const MFA_PAGE = "MFA Page Viewed";
export const ONBOARDING_STEP_NEXT = "Onboarding Step Next";
export const ONBOARDING_STEP_BACK = "Onboarding Step Back";
export const ONBOARDING_STEP_FINISH = "Onboarding Step Finish";
export const ACCOUNTS_PAGE = "Account Page";
export const BILLING_PAGE = "Billing Page";
export const POPUP_GET_STARTED = "Popup Get Started";
export const LOGOUT = "Logout";
export const MESSAGE_EVENTS = "Message Events";
export const MESSAGE_EVENT_OPEN = "Message Event Open";
export const APPS_PAGE = "Apps Page";
export const CREATE_APP_CLICK = "Create App Click";
export const CREATE_APP_CLOSE = "Create App Close";
export const CREATE_APP_CREATED = "Create App Created";
export const INVITE_USER_CREATED = "Invite User Created";
export const INVITE_USER_CREATE_ERROR = "Invite User Create Error";
export const CREATE_APP_CREATE_ERROR = "Create App Create Error";
export const ADD_PROVIDER_TO_APP_DIALOG = "Add Provider to App Dialog";
export const OTHER_PROVIDER_DIALOG = "Other Provider Dialog";
export const OTHER_PROVIDER_DIALOG_CLOSE = "Other Provider Dialog Close";
export const ADD_PROVIDER_TO_APP_DIALOG_CLOSE = "Add Provider to App Dialog Close";
export const UPDATE_PROVIDER_TO_APP_DIALOG = "Update Provider to App Dialog";
export const UPDATE_PROVIDER_TO_APP_DIALOG_CLOSE = "Update Provider to App Dialog Close";
export const LIST_PROVIDERS_DIALOG = "List Providers Dialog";
export const LIST_PROVIDERS_DIALOG_CLOSE = "List Providers Dialog Close";
export const UPDATE_PROVIDERS_DIALOG_CLOSE = "Update Providers Dialog Close";
export const PROVIDER_ADDED = "Provider Added";
export const PROVIDER_ADD_ERROR = "Provider Add Error";
export const PROVIDER_RULE_ADDED = "Provider Rule Added";
export const PROVIDER_RULE_ERROR = "Provider Rule Error";
export const USERS_PAGE = "Users Page";
export const MEMBERS_PAGE = "Members Page";
export const USER_SELECTED = "User Selected";
export const REINVITE_USER_SUCCESS = "Reinvite User Success";
export const REINVITE_USER_ERROR = "Reinvite User Error";
export const DELETE_INVITE_USER_SUCCESS = "Delete Invite User Success";
export const DELETE_INVITE_USER_ERROR = "Delete Invite User Error";
export const TEMPLATE_LIST_PAGE = "Template Page";
export const TEMPLATE_EDIT_PAGE = "Template Edit Page";
export const TEMPLATE_EDIT_SUCCESS = "Template Edit Success";
export const TEMPLATE_EDIT_FAILURE = "Template Edit Failure";
export const TEMPLATE_CREATE_PAGE = "Template Create Page";
export const TEMPLATE_CREATE_SUCCESS = "Template Create Success";
export const TEMPLATE_CREATE_FAILURE = "Template Create Failure";
export const EVENT_CREATE_SUCCESS = "Event Create Success";
export const EVENT_CREATE_FAILURE = "Event Create Failure";
export const EVENT_CREATE_TEMPLATE_SUCCESS = "Event Create Template Success";
export const EVENT_CREATE_TEMPLATE_FAILURE = "Event Create Template Failure";
export const EVENT_CHANNEL_ADD_SUCCESS = "Event Channel Add Success";
export const EVENT_CHANNEL_ADD_FAILURE = "Event Channel Add Failure";
export const EVENT_PUBLISH_SUCCESS = "Event Publish Success";
export const EVENT_PUBLISH_FAILURE = "Event Publish Failure";
export const EVENTS_LIST_PAGE = "Events Page";
export const EVENTS_CREATE_UPDATE_PAGE = "Events Create Update Page";
export const EVENTS_RELOAD_LOGS = "Events Reload Logs";
export const EVENT_ARCHIVED = "Event Archived";
export const EVENT_ARCHIVAL_FAILED = "Event Archival Failed";
export const EVENT_COPY = "Event Copy Success";
export const REVIEW_AND_PUBLISH_BUTTON_CLICK = "Review and Publish Button Click";
export const CURL_REQUEST_COPY_BUTTON_CLICK = "Curl Request Copy Button Click";
export const RESET_PASSWORD_PAGE = "Reset Password Page";
export const FORGOT_PASSWORD_PAGE = "Forgot Password Page Viewed";
export const SETTINGS_PAGE = "Settings Page Viewed";
export const CREATE_USER_CREATED = "Create User Created";
export const CREATE_USER_FAILED = "Create User Created";
export const ONBOARDING_PENDING_INVITE_PAGE = "Onboarding Pending Invite Page";
export const ONBOARDING_RAVEN_START = "Onboarding Raven Start";
export const ONBOARDING_RAZORPAY_START = "Onboarding Razorpay Start";
export const ONBOARDING_RAVEN_APP = "Onboarding Raven App";
export const ONBOARDING_RAVEN_INTEGRATIONS = "Onboarding Raven Integrations";
export const ONBOARDING_RAVEN_EVENTS = "Onboarding Raven Events";
export const ONBOARDING_RAVEN_TEST = "Onboarding Raven Test";
export const ONBOARDING_RAVEN_DONE = "Onboarding Raven Done";

export const RAZORPAY_SETUP_NAME = "Razorpay Setup Name";
export const RAZORPAY_SETUP_NAME_SUCCESS = "Razorpay Setup Name Success";
export const RAZORPAY_SETUP_NAME_FAILURE = "Razorpay Setup Name Failure";
export const RAZORPAY_SETUP_CHOOSE_NOTIFICATIONS = "Razorpay Setup Choose Notifications";
export const RAZORPAY_SETUP_UPDATE_NOTIFICATIONS_SUCCESS =
    "Razorpay Setup Update Notifications Success";
export const RAZORPAY_SETUP_UPDATE_NOTIFICATIONS_FAILURE =
    "Razorpay Setup Update Notifications Failure";
export const RAZORPAY_SETUP_VIEW_EVENTS = "Razorpay Setup View Events";
export const RAZORPAY_SETUP_AUTHORIZE = "Razorpay Setup Authorize";
export const RAZORPAY_SETUP_AUTHORIZE_SUCCESS = "Razorpay Setup Authorize Success";
export const RAZORPAY_SETUP_AUTHORIZE_FAILURE = "Razorpay Setup Authorize Failure";
export const RAZORPAY_SETUP_DONE = "Onboarding Razorpay Done";
export const RAZORPAY_REMOVE_EXTENSION_SUCCESS = "Razorpay Remove Extension Success";
export const RAZORPAY_REMOVE_EXTENSION_FAILURE = "Razorpay Remove Extension Failure";

//metrics
export const METRICS_PAGE = "Onboarding Metrics Page ";
export const METRICS_CARD_CLICK = "Metrics Card Click";
export const APPLY_QUANTILE_FILTERS = "Apply Quantile Filter";
export const APPLY_METRICS_FILTERS = "Apply Metrics Filters";
export const DOWNLOAD_CSV_CLICK = "Download Csv Click";
export const METRICS_TAB_SELECTED = "Metrics Tab Selected";

//changes
export const SEND_PASSWORD_RESET_LINK_BUTTON_CLICK = "Send Password Reset Link Button Click";
export const RESEND_PASSWORD_RESET_LINK_BUTTON_CLICK = "Resend Password Reset Link Button Click";
export const GO_TO_LOGIN_LINK_CLICK = "Go to Login Link Button click";
export const CONTINUE_BUTTON_CLICK = "Continue Button Click";
export const CONTINUE_WITH_GOOGLE_BUTTON_CLICK = "Continue With Google Button Click";
export const REGISTER_WITH_GOOGLE_LINK_CLICK = "Register with Google Button Click";
export const CREATE_ACCOUNT_BUTTON_CLICK = "Create Account Button Click-Invite";
export const JOIN_ACCOUNT_BUTTON_CLICK_INVITE = "Join Account Button Click-Invite";
export const JOIN_ACCOUNT_BUTTON_CLICK = "Join Account Button Click";
export const MFA_LOGIN_OTP_EMAIL = "MFA Login OTP-Email";
export const MFA_LOGIN_OTP_PHONE = "MFA Login OTP-Phone";
export const LOGIN_BUTTON_CLICK = "Login Button Click";
export const FORGOT_PASSWORD_BUTTON_CLICK = "Forgot Password Link Click";
export const RESEND_OTP_BUTTON_CLICK = "Resend Otp Button Click";
export const RESET_PASSWORD_BUTTON_CLICK = "Reset Password Button Click";
export const SEND_VERIFICATION_EMAIL_BUTTON_CLICK = "Send Verification Email Button Click";
export const MAKE_EMAIL_VERIFICATION_REQUEST = "Make Email Verification Request";
export const SEND_OTP_BUTTON_CLICK_INVITE = "Send Otp Button Click-Invite";
export const SEND_OTP_BUTTON_CLICK_DOMAIN = "Send Otp Button Click-Domain";
export const JOINED_ACCOUNT_DOMAIN = "Joined Account-Domain";
export const JOINED_ACCOUNT_INVITE = "Joined Account-Invite";
export const JOINED_ACCOUNT_EMAIL_INVITE = "Joined Account-Email Invite";
export const VERIFY_OTP_BUTTON_CLICK_DOMAIN = "Verify OTP Button Click-Domain";
export const VERIFY_OTP_BUTTON_CLICK_INVITE = "Verify OTP Button Click-Invite";

//logevents
export const EVENT_TIMELINE_DIALOG_BOX_CLOSE_BUTTON_CLICK =
    "Event Timline Dialog Box Close Button Click";
export const VIEW_PAYLOAD_BUTTON_CLICK = "View Payload Button Click";
export const LOG_ACTION_BUTTON_CLICK = "Log Action Button Click";
export const VIEW_RESPONSE_BUTTON_CLICK = "View Response Button Click";
export const VIEW_REQUEST_BUTTON_CLICK = "View Request Button Click";
export const LOG_VIEW_DIALOG_BOX = "Log View Dialog Box";
export const FETCH_LATEST_LOG_BUTTON_CLICK = "Fetch Latest Log Button Click";
export const DATE_FILTER_RESET_BUTTON_CLICK = "Date Filter Reset Button Click";
export const DATE_FILTER_APPLY_BUTTON_CLICK = "Date Filter Apply Button Click";
export const EXPORT_LOGS_BUTTON_CLICK = "Export Logs Button Click";
export const EXPORT_LOGS_DIALOG_BOX_VIEWED = "Export Logs Dialog Box Viewed";
export const EXPORT_LOGS_DIALOG_BOX_CLOSE_BUTTON_CLICK =
    "Export Logs Dialog Box Close Button Click";
export const EXPORT_LOGS_DONE_BUTTON_CLICK = "Export Logs Done Button Click";
export const EVENT_LOGS_FILTER_BUTTON_CLICK = "Event Logs Filter Button Click";
export const LOGS_FILTER = "Logs Filter Click";
export const SWITCH_TO_DEVELOPMENT_MODE = "Switch To Development Mode";
export const SWITCH_TO_PRODUCTION_MODE = "Switch To Production Mode";
//B2B SaaS Category
//b2b
export const ACCOUNT_CREATED = "Account Created";
export const ACCOUNT_DELETED = "Account Deleted";
export const SIGNED_UP = "Signed Up";
export const SIGNED_IN = "Signed In";
export const SIGNED_OUT = "Signed Out";
export const INVITE_SENT = "Invite Sent";
export const ACCOUNT_ADDED_USER = "Account Added User";
export const ACCOUNT_REMOVED_USER = "Account Removed User";
export const TRIAL_STARTED = "Trial Started";
export const TRIAL_ENDED = "Trial Ended";

export const JOINED_ORGANISATION = "Joined Organisation";
export const ROLE_CHANGED = "Role Change";
export const DOMAIN_ADDED = "Domain Added";
export const DOMAIN_REMOVED = "Domain Removed";
export const RESEND_DOMAIN_OTP = "Resend Domain Otp";
export const JOIN_ORGANISATION_BUTTON_CLICK = "Join Organisation Button Click";
export const CONFIRM_VERIFY_ORG_AUTH_BUTTON_CLICK = "Confirm Verify-org-auth Button Click";
export const JOIN_ORG_ACCOUNT_BUTTON_CLICK = "Join Org Account Button Click";
export const ADD_DOMAIN_BUTTON_CLICK = "Add Domain Button Click";
export const VERIFY_DOMAIN_TEXT_CLICK = "Verify Domain Text Click";
export const DELETE_DOMAIN_ICON_CLICK = "Delete Domain Icon Click";
export const REMOVE_DOMAIN_BUTTON_CLICK = "Remove Domain Button Click";
export const VERIFY_DOMAIN_SUBMIT_BUTTON_CLICK = "Verify Domain Submit Button Click";
export const DELETE_APP = "App Deleted";
