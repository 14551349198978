import jwtDecode from "jwt-decode";
import axios from "./axios";
import {
    LOGIN_URL,
    VERIFY_OTP_URL,
    RESEND_OTP_URL,
    REFRESH_TOKEN_URL,
    GET_USER_URL,
    GET_ACCOUNT_URL,
    GET_APPS_URL,
    REGISTER_URL,
    CHANGE_PWD_URL,
    INVITE_USER_URL,
    UPDATE_APP,
    DELETE_APP,
    GET_MEMBERS_URL,
    REINVITE_USER_URL,
    DELETE_INVITE_USER_URL,
    DELETE_MEMBER_URL,
    CHANGE_ROLE_URL,
    JOIN_ACCOUNT_URL,
    FORGOT_PWD_URL,
    GET_APP_EXTENSIONS_URL,
    UPDATE_ACCOUNT_IMAGE_URL,
    CREATE_CHECKOUT,
    CUSTOMER_PORTAL,
    USAGE_METRICS,
    BILLING_PLAN,
    CREATE_CUSTOMER,
    CREATE_SUBSCRIPTION,
    SEND_VERIFICATION_EMAIL_URL,
    VERIFY_EMAIL_URL,
    USER_EXISTS_URL,
    ADD_DOMAIN_URL,
    VERIFY_DOMAIN_EMAIL,
    RESEND_VERIFY_DOMAIN_OTP,
    DELETE_DOMAIN,
    DOMAIN_EXISTS_URL,
    JOIN_ORGANIZATION_OTP,
    JOIN_ORGANIZATION_OTP_RESEND,
    JOIN_ORGANIZATION_OTP_VERIFY,
    UPDATE_APP_NAME
} from "./url";
import createAuthRefreshInterceptor from "axios-auth-refresh";
import { API_BASE_URL } from "src/config";
import {
    getAccessToken,
    clearUserCredentials,
    getRefreshToken,
    storeAccessToken,
    storeRefreshToken,
    setOpenedEmailVerificationDialog
} from "../utils/credentials";
import { authRefresh } from "../events/userEvents";
import { logger } from "../utils/utils";

class UserService {
    setAxiosInterceptors = ({ onLogout }) => {
        axios.interceptors.response.use(
            response => response,
            error => {
                logger(error);
                let err = { ...error };
                if (error.response && error.response.status === 401) {
                    this.setSession(null);

                    if (onLogout) {
                        onLogout();
                    }
                } else if (error.response && error.response.status === 403) {
                    err.message = "Error 403: Permission denied.";
                } else if (
                    error.response &&
                    error.response.status >= 400 &&
                    error.response.status < 500
                ) {
                    err.message =
                        error.response && error.response.data && error.response.data.error
                            ? "Bad request: " + error.response.data.error
                            : "";
                } else if (
                    error.response &&
                    error.response.status >= 500 &&
                    error.response.status < 600
                ) {
                    console.log(error);
                    err.message =
                        error.response && error.response.data && error.response.data.error
                            ? "Server Error: " + error.response.data.error
                            : "";
                }

                return Promise.reject(err);
            }
        );
    };

    refreshAuthToken = () =>
        new Promise((resolve, reject) => {
            axios
                .get(REFRESH_TOKEN_URL + getRefreshToken())
                .then(tokenRefreshResponse => {
                    storeAccessToken(tokenRefreshResponse.data.access_token);
                    storeRefreshToken(tokenRefreshResponse.data.refresh_token);
                    this.setSession(tokenRefreshResponse.data.access_token);
                    resolve();
                })
                .catch(error => {
                    logger(error);
                    reject(error);
                });
        });

    loginWithEmailAndPassword = (email, password) =>
        new Promise((resolve, reject) => {
            axios
                .post(LOGIN_URL, { email, password })
                .then(response => {
                    if (response) {
                        if (
                            response.data &&
                            response.data["auth_credentials"] &&
                            response.data["auth_credentials"]["access_token"]
                        ) {
                            this.setSession(response.data["auth_credentials"]["access_token"]);
                            setOpenedEmailVerificationDialog(false);
                        }
                        resolve(response.data);
                    } else {
                        reject(response);
                    }
                })
                .catch(error => {
                    logger(error);
                    reject(error);
                });
        });

    userExists = email =>
        new Promise((resolve, reject) => {
            axios
                .get(USER_EXISTS_URL, {
                    params: {
                        email
                    }
                })
                .then(response => {
                    if (response) {
                        resolve(response.data);
                    } else {
                        reject(response);
                    }
                })
                .catch(error => {
                    logger(error);
                    reject(error);
                });
        });

    loginWithGoogle = token =>
        new Promise((resolve, reject) => {
            axios
                .post(LOGIN_URL, { google_id_token: token })
                .then(response => {
                    if (response) {
                        if (
                            response.data &&
                            response.data["auth_credentials"] &&
                            response.data["auth_credentials"]["access_token"]
                        ) {
                            this.setSession(response.data["auth_credentials"]["access_token"]);
                        }
                        resolve(response.data);
                    } else {
                        reject(response);
                    }
                })
                .catch(error => {
                    logger(error);
                    reject(error);
                });
        });

    resendOtp = token =>
        new Promise((resolve, reject) => {
            axios
                .post(RESEND_OTP_URL, { mfa_token: token })
                .then(response => {
                    if (response) {
                        resolve(response.data);
                    } else {
                        reject(response);
                    }
                })
                .catch(error => {
                    logger(error);
                    reject(error);
                });
        });

    verifyOtp = (token, otp) =>
        new Promise((resolve, reject) => {
            axios
                .post(VERIFY_OTP_URL, { mfa_token: token, otp: otp })
                .then(response => {
                    if (response) {
                        if (
                            response.data &&
                            response.data["auth_credentials"] &&
                            response.data["auth_credentials"]["access_token"]
                        ) {
                            this.setSession(response.data["auth_credentials"]["access_token"]);
                        }
                        resolve(response.data);
                    } else {
                        reject(response);
                    }
                })
                .catch(error => {
                    logger(error);
                    reject(error);
                });
        });

    logout = () => {
        this.setSession(null);
        clearUserCredentials();
    };

    getUser = () =>
        new Promise((resolve, reject) => {
            axios
                .get(GET_USER_URL)
                .then(response => {
                    if (response) {
                        resolve(response.data);
                    } else {
                        reject(response);
                    }
                })
                .catch(error => {
                    logger(error);
                    reject(error);
                });
        });

    updateUser = user =>
        new Promise((resolve, reject) => {
            axios
                .put(GET_USER_URL, user)
                .then(response => {
                    if (response) {
                        resolve(response.data);
                    } else {
                        reject(response);
                    }
                })
                .catch(error => {
                    logger(error);
                    reject(error);
                });
        });

    registerUser = user =>
        new Promise((resolve, reject) => {
            axios
                .post(REGISTER_URL, user)
                .then(response => {
                    if (response) {
                        this.setSession(response.data.auth_credentials.access_token);
                        setOpenedEmailVerificationDialog(true);
                        resolve(response.data);
                    } else {
                        reject(response);
                    }
                })
                .catch(error => {
                    logger(error);
                    reject(error);
                });
        });

    registerWithGoogle = (accountName, token) =>
        new Promise((resolve, reject) => {
            axios
                .post(REGISTER_URL, { account_name: accountName, google_id_token: token })
                .then(response => {
                    if (response) {
                        this.setSession(response.data.auth_credentials.access_token);
                        resolve(response.data);
                    } else {
                        reject(response);
                    }
                })
                .catch(error => {
                    logger(error);
                    reject(error);
                });
        });

    joinOrgWithGoogle = (accountSid, googleIdToken) =>
        new Promise((resolve, reject) => {
            axios
                .post(REGISTER_URL, {
                    account_sid: accountSid,
                    google_id_token: googleIdToken,
                    join_org: true
                })
                .then(response => {
                    if (response) {
                        this.setSession(response.data.auth_credentials.access_token);
                        resolve(response.data);
                    } else {
                        reject(response);
                    }
                })
                .catch(error => {
                    logger(error);
                    reject(error);
                });
        });

    getAccount = () =>
        new Promise((resolve, reject) => {
            axios
                .get(GET_ACCOUNT_URL)
                .then(response => {
                    if (response) {
                        resolve(response.data);
                    } else {
                        reject(response);
                    }
                })
                .catch(error => {
                    logger(error);
                    reject(error);
                });
        });

    updateAccountImage = image => {
        const formData = new FormData();
        formData.append("logo", image);
        const config = {
            headers: {
                "content-type": "multipart/form-data"
            }
        };
        return new Promise((resolve, reject) => {
            axios
                .put(UPDATE_ACCOUNT_IMAGE_URL, formData, config)
                .then(response => {
                    if (response) {
                        resolve(response.data);
                    } else {
                        reject(response);
                    }
                })
                .catch(error => {
                    logger(error);
                    reject(error);
                });
        });
    };

    updateAccount = account =>
        new Promise((resolve, reject) => {
            axios
                .put(GET_ACCOUNT_URL, account)
                .then(response => {
                    if (response) {
                        resolve(response.data);
                    } else {
                        reject(response);
                    }
                })
                .catch(error => {
                    logger(error);
                    reject(error);
                });
        });

    getApps = () =>
        new Promise((resolve, reject) => {
            axios
                .get(GET_APPS_URL)
                .then(response => {
                    if (response) {
                        resolve(response.data);
                    } else {
                        reject(response);
                    }
                })
                .catch(error => {
                    logger(error);
                    reject(error);
                });
        });

    createApp = app =>
        new Promise((resolve, reject) => {
            axios
                .post(GET_APPS_URL, app)
                .then(response => {
                    if (response) {
                        resolve(response.data);
                    } else {
                        reject(response);
                    }
                })
                .catch(error => {
                    logger(error);
                    reject(error);
                });
        });

    inviteUser = user =>
        new Promise((resolve, reject) => {
            axios
                .post(INVITE_USER_URL, user)
                .then(response => {
                    if (response) {
                        resolve(response.data);
                    } else {
                        reject(response);
                    }
                })
                .catch(error => {
                    logger(error);
                    reject(error);
                });
        });

    reInviteUser = userId =>
        new Promise((resolve, reject) => {
            axios
                .post(REINVITE_USER_URL, { user_id: userId })
                .then(response => {
                    if (response) {
                        resolve(response.data);
                    } else {
                        reject(response);
                    }
                })
                .catch(error => {
                    logger(error);
                    reject(error);
                });
        });

    deleteInvite = userId =>
        new Promise((resolve, reject) => {
            axios
                .delete(DELETE_INVITE_USER_URL, { data: { id: userId } })
                .then(response => {
                    if (response) {
                        resolve(response.data);
                    } else {
                        reject(response);
                    }
                })
                .catch(error => {
                    logger(error);
                    reject(error);
                });
        });

    deleteMember = userId =>
        new Promise((resolve, reject) => {
            axios
                .delete(DELETE_MEMBER_URL, { params: { id: userId } })
                .then(response => {
                    if (response) {
                        resolve(response.data);
                    } else {
                        reject(response);
                    }
                })
                .catch(error => {
                    logger(error);
                    reject(error);
                });
        });

    changeRole = newRole =>
        new Promise((resolve, reject) => {
            axios
                .post(CHANGE_ROLE_URL, newRole)
                .then(response => {
                    if (response) {
                        resolve(response.data);
                    } else {
                        reject(response);
                    }
                })
                .catch(error => {
                    logger(error);
                    reject(error);
                });
        });

    getMembers = setMembers =>
        new Promise((resolve, reject) => {
            axios
                .get(GET_MEMBERS_URL)
                .then(response => {
                    if (response) {
                        setMembers(response.data);
                        resolve(response.data);
                    } else {
                        reject(response);
                    }
                })
                .catch(error => {
                    logger(error);
                    reject(error);
                });
        });

    updateApp = app =>
        new Promise((resolve, reject) => {
            axios
                .put(UPDATE_APP.format(app.app_sid), app)
                .then(response => {
                    if (response) {
                        resolve(response.data);
                    } else {
                        reject(response);
                    }
                })
                .catch(error => {
                    logger(error);
                    reject(error);
                });
        });

    updateAppName = (appName, updatedAppName) =>
        new Promise((resolve, reject) => {
            axios
                .put(UPDATE_APP_NAME.format(appName), { name: updatedAppName })
                .then(response => {
                    if (response) {
                        resolve(response.data);
                    } else {
                        reject(response);
                    }
                })
                .catch(error => {
                    logger(error);
                    reject(error);
                });
        });

    deleteApp = app_name =>
        new Promise((resolve, reject) => {
            axios
                .delete(DELETE_APP, { data: { name: app_name } })
                .then(response => {
                    if (response) {
                        resolve(response.data);
                    } else {
                        reject(response);
                    }
                })
                .catch(error => {
                    logger(error);
                    reject(error);
                });
        });

    changePwd = newPwd =>
        new Promise((resolve, reject) => {
            axios
                .post(CHANGE_PWD_URL, newPwd)
                .then(response => {
                    if (response) {
                        resolve(response.data);
                    } else {
                        reject(response);
                    }
                })
                .catch(error => {
                    logger(error);
                    reject(error);
                });
        });

    joinAccount = member =>
        new Promise((resolve, reject) => {
            axios
                .post(JOIN_ACCOUNT_URL, member)
                .then(response => {
                    if (response) {
                        this.setSession(response.data.auth_credentials.access_token);
                        resolve(response.data);
                    } else {
                        reject(response);
                    }
                })
                .catch(error => {
                    logger(error);
                    reject(error);
                });
        });

    joinAccountWithGoogle = (googleIdToken, token) =>
        new Promise((resolve, reject) => {
            axios
                .post(JOIN_ACCOUNT_URL, { google_id_token: googleIdToken, token: token })
                .then(response => {
                    if (response) {
                        this.setSession(response.data.auth_credentials.access_token);
                        resolve(response.data);
                    } else {
                        reject(response);
                    }
                })
                .catch(error => {
                    logger(error);
                    reject(error);
                });
        });

    forgotPwd = email =>
        new Promise((resolve, reject) => {
            axios
                .post(
                    FORGOT_PWD_URL,
                    {},
                    {
                        params: {
                            email
                        }
                    }
                )
                .then(response => {
                    if (response) {
                        resolve(response.data);
                    } else {
                        reject(response);
                    }
                })
                .catch(error => {
                    logger(error);
                    reject(error);
                });
        });

    sendVerificationEmail = email =>
        new Promise((resolve, reject) => {
            axios
                .post(
                    SEND_VERIFICATION_EMAIL_URL,
                    {},
                    {
                        params: {
                            email
                        }
                    }
                )
                .then(response => {
                    if (response) {
                        resolve(response.data);
                    } else {
                        reject(response);
                    }
                })
                .catch(error => {
                    logger(error);
                    reject(error);
                });
        });

    verifyEmail = token =>
        new Promise((resolve, reject) => {
            axios
                .post(VERIFY_EMAIL_URL, { token: token })
                .then(response => {
                    if (response) {
                        resolve(response.data);
                    } else {
                        reject(response);
                    }
                })
                .catch(error => {
                    logger(error);
                    reject(error);
                });
        });

    setSession = accessToken => {
        if (accessToken) {
            axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
            createAuthRefreshInterceptor(axios, userService.refreshAuthToken);
        } else {
            delete axios.defaults.headers.common.Authorization;
        }
    };

    isAuthenticated = () => {
        return getAccessToken() == null;
    };

    //stripe
    createCheckout = priceId =>
        new Promise((resolve, reject) => {
            axios
                .post(CREATE_CHECKOUT, {
                    price_id: priceId
                })
                .then(response => {
                    if (response) {
                        resolve(response);
                    } else {
                        reject(response);
                    }
                })
                .catch(error => {
                    logger(error);
                    reject(error);
                });
        });

    createCustomerPortal = () =>
        new Promise((resolve, reject) => {
            axios
                .post(CUSTOMER_PORTAL)
                .then(response => {
                    if (response) {
                        resolve(response);
                    } else {
                        reject(response);
                    }
                })
                .catch(error => {
                    logger(error);
                    reject(error);
                });
        });

    getCustomer = () =>
        new Promise((resolve, reject) => {
            axios
                .get(CREATE_CUSTOMER)
                .then(response => {
                    if (response) {
                        resolve(response);
                    } else {
                        reject(response);
                    }
                })
                .catch(error => {
                    logger(error);
                    reject(error);
                });
        });

    createCustomer = customerObj =>
        new Promise((resolve, reject) => {
            axios
                .post(CREATE_CUSTOMER, customerObj)
                .then(response => {
                    if (response) {
                        resolve(response);
                    } else {
                        reject(response);
                    }
                })
                .catch(error => {
                    logger(error);
                    reject(error);
                });
        });

    updateCustomer = customerObj =>
        new Promise((resolve, reject) => {
            axios
                .put(CREATE_CUSTOMER, customerObj)
                .then(response => {
                    if (response) {
                        resolve(response);
                    } else {
                        reject(response);
                    }
                })
                .catch(error => {
                    logger(error);
                    reject(error);
                });
        });

    createSubscription = pmId =>
        new Promise((resolve, reject) => {
            axios
                .post(CREATE_SUBSCRIPTION, {
                    payment_method_id: pmId
                })
                .then(response => {
                    if (response) {
                        resolve(response);
                    } else {
                        reject(response);
                    }
                })
                .catch(error => {
                    logger(error);
                    reject(error);
                });
        });

    getUsageMetrics = () =>
        new Promise((resolve, reject) => {
            axios
                .get(USAGE_METRICS)
                .then(response => {
                    if (response) {
                        resolve(response);
                    } else {
                        reject(response);
                    }
                })
                .catch(error => {
                    logger(error);
                    reject(error);
                });
        });

    getBillingPlan = () =>
        new Promise((resolve, reject) => {
            axios
                .get(BILLING_PLAN)
                .then(response => {
                    if (response) {
                        resolve(response);
                    } else {
                        reject(response);
                    }
                })
                .catch(error => {
                    logger(error);
                    reject(error);
                });
        });

    domainExists = email =>
        new Promise((resolve, reject) => {
            axios
                .get(DOMAIN_EXISTS_URL.format(email))
                .then(response => {
                    if (response) {
                        resolve(response);
                    } else {
                        reject(response);
                    }
                })
                .catch(error => {
                    logger(error);
                    reject(error);
                });
        });

    joinOrganizationSendOtp = email =>
        new Promise((resolve, reject) => {
            axios
                .post(JOIN_ORGANIZATION_OTP, { email: email })
                .then(response => {
                    if (response) {
                        resolve(response);
                    } else {
                        reject(response);
                    }
                })
                .catch(error => {
                    logger(error);
                    reject(error);
                });
        });

    joinOrganizationResendOtp = (email, mfa_token) =>
        new Promise((resolve, reject) => {
            axios
                .post(JOIN_ORGANIZATION_OTP_RESEND, {
                    email: email,
                    mfa_token: mfa_token
                })
                .then(response => {
                    if (response) {
                        resolve(response);
                    } else {
                        reject(response);
                    }
                })
                .catch(error => {
                    logger(error);
                    reject(error);
                });
        });

    joinOrganizationVerifyOtp = (email, mfa_token, otp, account_sid) =>
        new Promise((resolve, reject) => {
            axios
                .post(JOIN_ORGANIZATION_OTP_VERIFY, {
                    email: email,
                    mfa_token: mfa_token,
                    otp: otp,
                    account_sid: account_sid
                })
                .then(response => {
                    if (response) {
                        resolve(response);
                    } else {
                        reject(response);
                    }
                })
                .catch(error => {
                    logger(error);
                    reject(error);
                });
        });

    addDomain = domain =>
        new Promise((resolve, reject) => {
            axios
                .post(ADD_DOMAIN_URL, domain)
                .then(response => {
                    if (response) {
                        resolve(response);
                    } else {
                        reject(response);
                    }
                })
                .catch(error => {
                    logger(error);
                    reject(error);
                });
        });

    deleteDomain = domain =>
        new Promise((resolve, reject) => {
            axios
                .delete(DELETE_DOMAIN.format(domain))
                .then(response => {
                    if (response) {
                        resolve(response);
                    } else {
                        reject(response);
                    }
                })
                .catch(error => {
                    logger(error);
                    reject(error);
                });
        });

    getDomains = () =>
        new Promise((resolve, reject) => {
            axios
                .get(ADD_DOMAIN_URL)
                .then(response => {
                    if (response) {
                        resolve(response);
                    } else {
                        reject(response);
                    }
                })
                .catch(error => {
                    logger(error);
                    reject(error);
                });
        });

    verifyDomainOtp = otpDetails =>
        new Promise((resolve, reject) => {
            axios
                .post(VERIFY_DOMAIN_EMAIL, otpDetails)
                .then(response => {
                    if (response) {
                        resolve(response);
                    } else {
                        reject(response);
                    }
                })
                .catch(error => {
                    logger(error);
                    reject(error);
                });
        });

    resendDomainOtp = mfa_token =>
        new Promise((resolve, reject) => {
            axios
                .post(RESEND_VERIFY_DOMAIN_OTP, mfa_token)
                .then(response => {
                    if (response) {
                        resolve(response);
                    } else {
                        reject(response);
                    }
                })
                .catch(error => {
                    logger(error);
                    reject(error);
                });
        });
}

const userService = new UserService();

export default userService;
