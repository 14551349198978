import React, { useState } from "react";
import { Snackbar } from "@material-ui/core";
import * as analytics from "src/core/components/analytics";

function CopyToClipboard({ copyText, tracker, children }) {
    const [openSnackbar, setOpenSnackbar] = useState(false);

    const handleCopyButtonClick = () => {
        setOpenSnackbar(true);
        navigator.clipboard.writeText(copyText);
        if (tracker) {
            analytics.logEvent(tracker);
        }
    };

    return (
        <>
            <div onClick={handleCopyButtonClick} style={{ cursor: "default" }}>
                {children}
            </div>
            <Snackbar
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                open={openSnackbar}
                onClose={() => setOpenSnackbar(false)}
                autoHideDuration={2000}
                message="Copied to clipboard"
            />
        </>
    );
}

export default CopyToClipboard;
