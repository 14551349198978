export const UPDATE_PROVIDER_REQUEST = "@provider/update-provider-request";
export const UPDATE_PROVIDER_SUCCESS = "@provider/update-provider-success";
export const UPDATE_PROVIDER_FAILURE = "@provider/update-provider-failure";
export const ADD_PROVIDER_SUCCESS = "@provider/add-provider-success";
export const UPDATE_RULE_SUCCESS = "@provider/update-rule-success";
export const ADD_RULE_SUCCESS = "@provider/add-rule-success";

export function updateProviderRequest(appId) {
    return {
        type: UPDATE_PROVIDER_REQUEST
    };
}

export function updateProviderSuccess(provider) {
    return {
        type: UPDATE_PROVIDER_SUCCESS,
        payload: {
            provider
        }
    };
}

export function updateProviderFailure() {
    return {
        type: UPDATE_PROVIDER_FAILURE
    };
}

export function addProviderSuccess(provider) {
    return {
        type: ADD_PROVIDER_SUCCESS,
        payload: {
            provider
        }
    };
}

export function updateRuleSuccess(rule) {
    return {
        type: UPDATE_RULE_SUCCESS,
        payload: {
            rule
        }
    };
}

export function addRuleSuccess(rule) {
    return {
        type: ADD_RULE_SUCCESS,
        payload: {
            rule
        }
    };
}

