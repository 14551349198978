export const GET_MESSAGE_EVENTS_REQUEST = '@reporting/get-message-events-request';
export const GET_MESSAGE_EVENTS_SUCCESS = '@reporting/get-message-events-success';
export const GET_MESSAGE_EVENTS_FAILURE = '@reporting/get-message-events-failure';

export function getMessageEventsRequest(appId) {
    return {
        type: GET_MESSAGE_EVENTS_REQUEST,
        payload: {
            appId
        }
    }
}

export function getMessageEventsSuccess(appId, events) {
    return {
        type: GET_MESSAGE_EVENTS_SUCCESS,
        payload: {
            events: events,
            eventsAppId: appId
        }
    }
}

export function getMessageEventsFailure() {
    return {
        type: GET_MESSAGE_EVENTS_FAILURE
    }
}