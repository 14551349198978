import React from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import clsx from "clsx";
import { Box, makeStyles, Typography, Link } from "@material-ui/core";
import * as utils from "src/core/utils/utils";
import * as analytics from "src/core/components/analytics";
import WarningIcon from "@material-ui/icons/Warning";
import VerifyEmailDialog from "src/views/auth/VerifyEmailLink/VerifyEmailDialog";
import {
    getOpenedEmailVerificationDialog,
    setOpenedEmailVerificationDialog
} from "src/core/utils/credentials";

const useStyles = makeStyles(theme => ({}));

function EmailVerificationBanner({ className, onMobileNavOpen, ...rest }) {
    const classes = useStyles();
    const [openVerifyEmailDialog, setOpenVerifyEmailDialog] = React.useState(
        !getOpenedEmailVerificationDialog()
    );

    const { user } = useSelector(state => state.account);

    const handleOpenVerifyEmailDialog = () => {
        setOpenVerifyEmailDialog(!openVerifyEmailDialog);
        setOpenedEmailVerificationDialog(true);
    };

    const handleCloseVerifyEmailDialog = () => {
        setOpenVerifyEmailDialog(false);
        setOpenedEmailVerificationDialog(true);
    };

    return (
        <>
            {!user["verified"] && (
                <Box
                    p={0.5}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    marginLeft={0}
                    marginRight={0}
                    borderRadius={0}
                    style={{
                        color: utils.messageStatusColorArray["ERROR"][0],
                        backgroundColor: utils.messageStatusColorArray["ERROR"][1]
                    }}
                >
                    <WarningIcon fontSize="small" />
                    <Box mr={1} />
                    <Typography variant="body2">
                        Your email is not verified yet. Please check your inbox for the verification
                        email or
                    </Typography>
                    <Box mr={0.5} />
                    <Link component="button" variant="body2" onClick={handleOpenVerifyEmailDialog}>
                        click here
                    </Link>
                    <Box mr={0.5} />
                    <Typography variant="body2">to resend.</Typography>
                </Box>
            )}

            {openVerifyEmailDialog && !user["verified"] && (
                <VerifyEmailDialog
                    user={user}
                    openVerifyEmailDialog={openVerifyEmailDialog}
                    handleCloseVerifyEmailDialog={handleCloseVerifyEmailDialog}
                />
            )}
        </>
    );
}

EmailVerificationBanner.propTypes = {
    className: PropTypes.string
};

export default EmailVerificationBanner;
