/* eslint-disable no-use-before-define */
/* eslint-disable react/prop-types */
import React, { useEffect } from "react";
import { useLocation, matchPath } from "react-router";
import { Link as RouterLink } from "react-router-dom";
import { useSelector } from "react-redux";
import PerfectScrollbar from "react-perfect-scrollbar";
import PropTypes from "prop-types";
import {
    Box,
    Divider,
    Drawer,
    Hidden,
    Link,
    TextField,
    MenuItem,
    List,
    ListSubheader,
    Typography,
    makeStyles,
    useTheme,
    Card,
    CardActionArea
} from "@material-ui/core";
import {
    Settings as SettingsIcon,
    FileText as FileIcon,
    PieChart as PieChartIcon,
    Send as SendIcon,
    Zap as ZapIcon,
    FastForward,
    ArrowDown,
    Users as UsersIcon
} from "react-feather";
import * as utils from "src/core/utils/utils";
import Logo from "src/components/Logo";
import NavItem from "./NavItem";
import LoadingScreen from "src/components/LoadingScreen";
import { ContactSupport, ContactSupportOutlined, PlusOne } from "@material-ui/icons";
import { Button } from "@material-ui/core";
import ExpandMore from "@material-ui/icons/ExpandMore";
import { SelectAppDialog } from "src/views/Settings/AppsView/apps";
import { getOnboardingDisabledApps } from "src/core/utils/credentials";
import HelpSupport from "../HelpSupport";
import { BiSupport } from "react-icons/bi";
import { TbFileDescription } from "react-icons/tb";
import { RAVEN_DOCS_URL } from "src/core/api/url";

const navConfig = [
    // {
    //     subheader: "",
    //     items: [
    //         {
    //             title: "Get Started",
    //             icon: FastForward,
    //             href: "/app/get-started"
    //         }
    //     ]
    // },
    // {
    //     subheader: "Dashboard",
    //     items: [
    //         {
    //             title: "Metrics",
    //             icon: PieChartIcon,
    //             href: "/app/dashboard/metrics"
    //         },
    //         {
    //             title: "Logs",
    //             icon: FileIcon,
    //             href: "/app/dashboard/logs"
    //         }
    //     ]
    // },
    {
        subheader: "Management",
        items: [
            {
                title: "Templates",
                icon: ZapIcon,
                href: "/app/management/templates"
            },
            // {
            //     title: "Users",
            //     icon: UsersIcon,
            //     href: "/app/management/users"
            // },
            // {
            //     title: "Integrations",
            //     icon: SendIcon,
            //     href: "/app/management/integrations"
            // }
        ]
    }
];

const navConfigSupport = {
    items: [
        {
            title: "Settings",
            icon: SettingsIcon,
            href: "/app/management/settings"
        },
        {
            title: "Docs",
            icon: TbFileDescription,
            href: RAVEN_DOCS_URL,
            callback: () => window.open(RAVEN_DOCS_URL, "_blank")
        },
        {
            title: "Support",
            icon: BiSupport,
            href: "",
            callback: () => window.$chatwoot.toggle()
        }
    ]
};

function renderNavItems({ account, app, items, ...rest }) {
    return (
        <List disablePadding>
            {items
                .filter((acc, item) => {
                    if (acc.title == "Users") {
                        if (
                            account["preferences"] == null ||
                            !account["preferences"]["user_management_enabled"]
                        ) {
                            return false;
                        }
                    }
                    return true;
                })
                .reduce((acc, item) => reduceChildRoutes({ account, app, acc, item, ...rest }), [])}
        </List>
    );
}

const handleAppChange = event => {
    event.persist();
    // onAppSelected(event.target.value);
};

let onboardingDisabledApps = getOnboardingDisabledApps();

function reduceChildRoutes({ account, app, acc, pathname, item, depth = 0 }) {
    const key = item.title + depth;

    if (onboardingDisabledApps && item["title"] === "Get Started") {
        let found = onboardingDisabledApps.find(it => it === app["app_sid"]);
        if (found) {
            return acc;
        }
    }

    if (item.items) {
        const open = matchPath(pathname, {
            path: item.href,
            exact: false
        });

        acc.push(
            <NavItem
                depth={depth}
                icon={item.icon}
                key={key}
                info={item.info}
                open={Boolean(open)}
                title={item.title}
            >
                {renderNavItems({
                    account: account,
                    depth: depth + 1,
                    pathname,
                    items: item.items
                })}
            </NavItem>
        );
    } else {
        acc.push(
            <NavItem
                depth={depth}
                href={item.href}
                icon={item.icon}
                key={key}
                info={item.info}
                title={item.title}
                callback={item.callback ? item.callback : null}
            />
        );
    }

    return acc;
}

const useStyles = makeStyles(() => ({
    mobileDrawer: {
        width: 256
    },
    desktopDrawer: {
        width: 256,
        top: 64,
        height: "calc(100% - 64px)"
    },
    avatar: {
        cursor: "pointer",
        width: 64,
        height: 64
    }
}));

function checkPath(pathname, href) {
    return matchPath(pathname, {
        path: href,
        exact: false
    });
}

function NavBar({
    openMobile,
    onMobileClose,
    selectedApp: selectedApp,
    apps: apps,
    onAppSelected: onAppSelected
}) {
    const classes = useStyles();
    const location = useLocation();
    const theme = useTheme();
    const { account } = useSelector(state => state.account);

    useEffect(() => {
        if (openMobile && onMobileClose) {
            onMobileClose();
        }
        // eslint-disable-next-line
    }, [location.pathname]);

    const [open, setOpen] = React.useState(false);
    const [helpDialogOpen, setHelpDialogOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = value => {
        setOpen(false);
        if (value) {
            onAppSelected(value);
        }
    };

    if (selectedApp == null && apps != null && apps.length > 0) {
        return <LoadingScreen />;
    }

    const content = (
        <Box height="100%" display="flex" flexDirection="column">
            <PerfectScrollbar options={{ suppressScrollX: true }}>
                <Hidden lgUp>
                    <Box p={2} display="flex" justifyContent="center">
                        {/* <RouterLink to="/"> */}
                        <Logo />
                        {/* </RouterLink> */}
                    </Box>
                </Hidden>

                <Box mt={4} p={2} pt={1} pb={1} display="flex" justifyContent="center">
                    <Button
                        fullWidth
                        onClick={handleClickOpen}
                        variant="outlined"
                        style={{ textTransform: "none", justifyContent: "flex-start" }}
                    >
                        <Box
                            width="100%"
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                        >
                            <Box display="flex" flexDirection="column" alignItems="flex-start">
                                <Typography
                                    variant="body1"
                                    // color="textSecondary"
                                    style={{ marginBottom: 1 }}
                                >
                                    {selectedApp.name}
                                </Typography>
                                <Typography variant="caption" color="textSecondary">
                                    {utils.titleCase(selectedApp.type)}
                                </Typography>
                            </Box>
                            <ExpandMore htmlColor={theme.palette.text.secondary} />
                        </Box>
                    </Button>
                </Box>
                <Box p={2}>
                    {navConfig.map(config => (
                        <List
                            key={config.subheader}
                            subheader={
                                <ListSubheader disableGutters disableSticky>
                                    {config.subheader}
                                </ListSubheader>
                            }
                        >
                            {renderNavItems({
                                account: account,
                                items: config.items,
                                pathname: location.pathname,
                                app: selectedApp
                            })}
                        </List>
                    ))}
                </Box>
                <Divider />
                <Box p={2}>
                    <List>
                        {renderNavItems({
                            account: account,
                            items: navConfigSupport.items,
                            pathname: location.pathname,
                            app: selectedApp
                        })}
                    </List>
                </Box>

                {/* <Card
                    onClick={e => setHelpDialogOpen(true)}
                    style={{ margin: 15 }}
                    variant="outlined"
                >
                    <CardActionArea>
                        <Box
                            display={"flex"}
                            alignItems={"center"}
                            bgcolor={"background.dark"}
                            p={2}
                        >
                            <ContactSupportOutlined
                                fontSize="small"
                                htmlColor={theme.palette.text.secondary}
                            />
                            <Typography
                                variant="h6"
                                color="textSecondary"
                                style={{ marginLeft: 5 }}
                            >
                                Help &amp; Support
                            </Typography>
                        </Box>
                    </CardActionArea>
                </Card>
                {helpDialogOpen && <HelpSupport handleClose={() => setHelpDialogOpen(false)} />} */}
            </PerfectScrollbar>
            <SelectAppDialog
                open={open}
                selectedApp={selectedApp}
                apps={apps}
                onClose={handleClose}
            />
        </Box>
    );

    return (
        <>
            <Hidden lgUp>
                <Drawer
                    anchor="left"
                    classes={{ paper: classes.mobileDrawer }}
                    onClose={onMobileClose}
                    open={openMobile}
                    variant="temporary"
                >
                    {content}
                </Drawer>
            </Hidden>
            {/* <Hidden mdDown>
                <Drawer
                    anchor="left"
                    classes={{ paper: classes.desktopDrawer }}
                    open
                    variant="persistent"
                >
                    {content}
                </Drawer>
            </Hidden> */}
        </>
    );
}

NavBar.propTypes = {
    onMobileClose: PropTypes.func,
    openMobile: PropTypes.bool
};

export default NavBar;
