import React, { useState, useRef } from "react";
import { setSelectedApp } from "src/core/repo/userRepo";
import { useDispatch, useSelector } from "react-redux";
import {
    makeStyles,
    Box,
    Typography,
    Button,
    Menu,
    MenuItem,
    ListItemIcon,
    Divider,
    Switch,
    Popover
} from "@material-ui/core";
import * as analytics from "src/core/components/analytics";
import { setPreferences } from "src/core/components/FirebasePreferences";
import { SelectAppDialog } from "src/views/Settings/AppsView/apps";
import { useHistory } from "react-router";
import CopyToClipboard from "src/components/CopyToClipboard";
import { FiSettings } from "react-icons/fi";
import { CgArrowsExchange } from "react-icons/cg";
import { AiFillCaretRight, AiOutlineFileProtect } from "react-icons/ai";
import { MdOutlineContentCopy } from "react-icons/md";
import ExpandMore from "@material-ui/icons/ExpandMore";
const useStyles = makeStyles(theme => ({
    test: {
        textTransform: "none",
        borderRadius: 12,
        paddingLeft: 12,
        paddingRight: 12,
        paddingTop: 3.5,
        paddingBottom: 2,
        backgroundColor: theme.palette.warning.main,
        fontSize: theme.typography.pxToRem(11),
        fontWeight: theme.typography.fontWeightMedium,
        color: "white",
        cursor: "pointer",
        caretColor: "transparent"
    },
    poper_container: {
        paddingTop: "10px",
        paddingBottom: "10px",
        justifyContent: "center",
        caretColor: "transparent"
    },
    poper_element: {
        display: "flex",
        alignItems: "center",
        cursor: "pointer",
        "&:hover": {
            backgroundColor: " #f1f1f1",
            color: "white"
        }
    },
    popover: {
        marginTop: 9
    },
    texttransformNone: {
        textTransform: "none",
        backgroundColor: "transparent",
        borderColor: "white",
        color: "white",
        padding: "3px 10px",
        minWidth: 150,
        justifyContent: "space-between",
        "&:hover": {
            backgroundColor: "transparent"
        }
    },
    textSize: {
        fontSize: "11px"
    },
    active: {
        backgroundColor: theme.palette.primary.dark
    },
    actionIcon: {
        marginRight: theme.spacing(1)
    },
    action: {
        // marginBottom: theme.spacing(1),
        "& + &": {
            marginLeft: theme.spacing(1)
        }
    },
    appSwitchPopover: {
        height: 400,
        marginTop: -42
    },
    menuItemNoHover: {
        display: "flex",
        alignItems: "center",
        padding: "8px 16px",
        cursor: "default"
    },
    menuItem: {
        paddingTop: 10,
        paddingBottom: 10
    },
    menuItemTitle: {
        fontSize: 15
    }
}));

export default function AppModeMenu({ onAppSelected }) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const { selectedApp, apps, user, account } = useSelector(state => state.account);
    const isTest = selectedApp.type === "development";
    const buttonRef = useRef(null);
    const [isOpen, setOpen] = useState(false);
    const [isTestMode, setIsTestMode] = useState(isTest);
    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleAppSwitchClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleAppSwitchClose = () => {
        setAnchorEl(null);
    };

    const appSwitchOpen = Boolean(anchorEl);
    const id = appSwitchOpen ? "simple-popover" : undefined;

    const formatAppId = () => {
        const appId = selectedApp.app_sid;
        const n = appId.length;
        return appId.substring(0, 17) + "..." + appId.substring(n - 4, n);
    };

    const handleTestMode = e => {
        const value = e.target.checked;
        setIsTestMode(value);
        analytics.logEvent(
            value ? analytics.SWITCH_TO_DEVELOPMENT_MODE : analytics.SWITCH_TO_PRODUCTION_MODE,
            {
                userName: user.email,
                accountName: account.name,
                app: selectedApp.name
            }
        );
        const temp = apps.find(
            apps =>
                apps.type === (value ? "development" : "production") &&
                apps.name == selectedApp.name
        );
        dispatch(setSelectedApp(temp));
        setPreferences(user.id, "selectedApp", temp.app_sid);
        handleClose();
    };

    return (
        <div>
            <Button
                disabled={!user["verified"]}
                className={classes.texttransformNone}
                size="small"
                disableRipple
                variant="outlined"
                onClick={handleOpen}
                endIcon={<ExpandMore variant="contained" />}
                ref={buttonRef}
            >
                <Box>
                    <Typography
                        variant="caption"
                        style={{ color: "#f1f1f1", display: "block", textAlign: "left" }}
                    >
                        Current App
                    </Typography>
                    <Typography
                        variant="body2"
                        style={{
                            textAlign: "left"
                        }}
                    >
                        {selectedApp.name}
                    </Typography>
                </Box>
                <Box m={1} />
                {selectedApp.type === "development" && (
                    <Typography className={classes.test} variant="body2">
                        TEST
                    </Typography>
                )}
            </Button>
            <Menu
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom"
                }}
                PaperProps={{
                    className: classes.popover,
                    style: {
                        width: 300
                    }
                }}
                MenuListProps={{ style: { padding: 0 } }}
                getContentAnchorEl={null}
                anchorEl={buttonRef.current}
                open={isOpen}
            >
                <Box className={classes.menuItemNoHover} justifyContent="space-between">
                    <Box style={{ display: "flex" }}>
                        <Typography className={classes.test} variant="body2">
                            TEST
                        </Typography>
                        <Box mr={2} />
                        <Typography variant="inherit" className={classes.menuItemTitle}>
                            Test Mode
                        </Typography>
                    </Box>
                    <ListItemIcon style={{ marginLeft: -5 }}>
                        <Switch
                            value={isTestMode}
                            defaultChecked={isTest}
                            onChange={handleTestMode}
                            size="small"
                        />
                    </ListItemIcon>
                </Box>
                <Divider />
                <MenuItem className={classes.menuItem}>
                    <CopyToClipboard copyText={account.secret_key}>
                        <Box display="flex" alignItems="center" style={{ cursor: "pointer" }}>
                            <ListItemIcon>
                                <AiOutlineFileProtect fontSize="large" />
                            </ListItemIcon>
                            <Box>
                                <Typography
                                    variant="subtitle2"
                                    gutterBottom
                                    style={{ color: "#9699a4" }}
                                >
                                    App ID
                                </Typography>
                                <Typography
                                    variant="inherit"
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "space-between"
                                    }}
                                    className={classes.menuItemTitle}
                                    noWrap
                                >
                                    {formatAppId()}
                                    <ListItemIcon>
                                        <MdOutlineContentCopy
                                            fontSize="large"
                                            style={{ marginLeft: 24 }}
                                        ></MdOutlineContentCopy>
                                    </ListItemIcon>
                                </Typography>
                            </Box>
                        </Box>
                    </CopyToClipboard>
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        let searchQueryObj = {
                            tab: "app"
                        };
                        history.push({
                            pathname: "/app/management/settings",
                            search: "?" + new URLSearchParams(searchQueryObj).toString()
                        });
                        handleClose();
                    }}
                    className={classes.menuItem}
                >
                    <ListItemIcon>
                        <FiSettings fontSize="large" />
                    </ListItemIcon>
                    <Typography variant="inherit" className={classes.menuItemTitle} noWrap>
                        App Settings
                    </Typography>
                </MenuItem>
                <Divider />
                <MenuItem
                    aria-describedby={id}
                    // ref={appSwitchRef}
                    onClick={handleAppSwitchClick}
                    className={classes.menuItem}
                    style={{ display: "flex", justifyContent: "space-between" }}
                >
                    <Box style={{ display: "flex", alignItems: "center" }}>
                        <ListItemIcon>
                            <CgArrowsExchange fontSize="large" />
                        </ListItemIcon>
                        <Typography variant="inherit" className={classes.menuItemTitle} noWrap>
                            Switch to a different app
                        </Typography>
                    </Box>
                    <ListItemIcon>
                        <AiFillCaretRight fontSize="large" />
                    </ListItemIcon>
                </MenuItem>
            </Menu>
            <Popover
                id={id}
                open={appSwitchOpen}
                anchorEl={anchorEl}
                onClose={handleAppSwitchClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left"
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right"
                }}
                PaperProps={{
                    className: classes.appSwitchPopover,
                    style: {}
                }}
            >
                <SelectAppDialog
                    selectedApp={selectedApp}
                    apps={apps}
                    isList={true}
                    onClose={() => {
                        handleAppSwitchClose();
                        handleClose();
                    }}
                />
            </Popover>
        </div>
    );
}
