import firebase from 'firebase'

export function setPreferences(user_id, key, value) {
    firebase.database().ref('raven_users/' + user_id).set(
        { [key]: value }
    );
}

export function getPreferences(user_id, key) {
    let ref = firebase.database().ref(`raven_users/${user_id}/${key}`);
    return ref;
}
