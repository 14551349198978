import React from "react";
import {
    Box,
    Button,
    SvgIcon,
    makeStyles,
    Typography,
    TextField,
    Card,
    useTheme,
    Tooltip,
    Divider
} from "@material-ui/core";
import * as utils from "src/core/utils/utils";
import { useDispatch, useSelector } from "react-redux";
import useIsMountedRef from "src/hooks/useIsMountedRef";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import { PlusCircle as PlusCircleIcon } from "react-feather";
import CachedIcon from "@material-ui/icons/Cached";
import { CardContent } from "@material-ui/core";
import { DialogContent } from "@material-ui/core";
import * as analytics from "src/core/components/analytics";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import AddAccountAppDialog from "./AddAccountAppDialog";
import { getApps } from "src/core/repo/userRepo";
import { getUser } from "src/core/utils/credentials";
import { setPreferences } from "src/core/components/FirebasePreferences";
import { setSelectedApp } from "src/core/repo/userRepo";

const useStyles = makeStyles(theme => ({
    root: {
        minHeight: "100%",
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3)
    },
    actionIcon: {
        marginRight: theme.spacing(1)
    },
    action: {
        // marginBottom: theme.spacing(1),
        "& + &": {
            marginLeft: theme.spacing(1)
        }
    },
    active: {
        backgroundColor: "#ebebeb"
    }
}));

export function SelectAppDialog(props) {
    const theme = useTheme();
    const classes = useStyles();
    const dispatch = useDispatch();
    const { onClose, apps, selectedApp, open, isList } = props;
    const user = getUser();
    const handleClose = () => {
        onClose();
    };
    const handleListItemClick = value => {
        if (!user["verified"]) {
            const testapp = apps.find(apps => apps.type === "development");
            setPreferences(user.id, "selectedApp", testapp.app_sid);
        } else {
            if (selectedApp.app_sid !== value.app_sid) {
                setPreferences(user.id, "selectedApp", value.app_sid);
            }
        }
        onClose(value);
    };
    let copyApps = apps.filter(e => e.type === "production");

    copyApps.sort((a, b) => a["name"].localeCompare(b["name"]));

    return !isList ? (
        <Dialog
            onClose={handleClose}
            fullWidth
            maxWidth="xs"
            aria-labelledby="simple-dialog-title"
            open={open}
        >
            <DialogTitle disableTypography>
                <Typography variant="h4">Switch App</Typography>
            </DialogTitle>
            <DialogContent style={{ padding: 0 }} dividers={true}>
                <List>
                    {copyApps.map(app => (
                        <ListItem
                            button
                            onClick={() => handleListItemClick(app)}
                            key={app["app_sid"]}
                            selected={app["name"] === selectedApp["name"]}
                        >
                            <Box
                                width="100%"
                                display="flex"
                                alignItems="center"
                                justifyContent="space-between"
                            >
                                <Box display="flex" alignItems="center">
                                    {app["name"] === selectedApp["name"] && (
                                        <CheckCircleIcon
                                            style={{ padding: "3 px" }}
                                            htmlColor={theme.palette.success.main}
                                        />
                                    )}
                                    <Typography
                                        variant="subtitle1"
                                        style={
                                            app["name"] === selectedApp["name"]
                                                ? { marginBottom: 1, marginLeft: 10 }
                                                : { marginBottom: 1, marginLeft: 35 }
                                        }
                                    >
                                        {app["name"]}
                                    </Typography>
                                </Box>
                            </Box>
                        </ListItem>
                    ))}
                </List>
            </DialogContent>
        </Dialog>
    ) : (
        <List style={{ minWidth: 250 }}>
            {copyApps.map(app => (
                <ListItem
                    button
                    onClick={() => handleListItemClick(app)}
                    key={app["app_sid"]}
                    selected={app["name"] === selectedApp["name"]}
                >
                    <Box
                        width="100%"
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                    >
                        <Box display="flex" alignItems="center">
                            {app["name"] === selectedApp["name"] && (
                                <CheckCircleIcon
                                    style={{ padding: "3 px" }}
                                    htmlColor={theme.palette.success.main}
                                />
                            )}
                            <Typography
                                variant="subtitle1"
                                style={
                                    app["name"] === selectedApp["name"]
                                        ? { marginBottom: 1, marginLeft: 10 }
                                        : { marginBottom: 1, marginLeft: 35 }
                                }
                            >
                                {app["name"]}
                            </Typography>
                        </Box>
                    </Box>
                </ListItem>
            ))}
        </List>
    );
}


export default function Apps({ apps, selectedApp, onAppSwitch, ...rest }) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const isMountedRef = useIsMountedRef();
    const { user } = useSelector(state => state.account);
    const [open, setOpen] = React.useState(false);
    const [createAppOpen, setCreateAppOpen] = React.useState(false);

    const openCreateAppDialog = () => {
        setCreateAppOpen(true);
        analytics.logEvent(analytics.CREATE_APP_CLICK);
    };

    const handleCreateAppDialogClose = success => {
        setCreateAppOpen(false);
        analytics.logEvent(analytics.CREATE_APP_CLOSE);
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = value => {
        setOpen(false);
        if (value) {
            onAppSwitch(value);
        }
    };

    return (
        <>
            <Box display="flex" alignItems="start" mb={1} justifyContent="right">
                <Box display="flex" alignItems="center">
                    {apps && apps.length > 1 && (
                        <>
                            <Button
                                color="secondary"
                                variant="outlined"
                                className={classes.action}
                                onClick={handleClickOpen}
                                size="small"
                                component="button"
                            >
                                <SvgIcon fontSize="small" className={classes.actionIcon}>
                                    <CachedIcon />
                                </SvgIcon>
                                Switch App
                            </Button>
                            <Box ml={2} />
                        </>
                    )}
                    <Tooltip
                        title={
                            <Typography variant="caption">
                                {!user["verified"] ? "Verify email first" : ""}
                            </Typography>
                        }
                    >
                        <Button
                            color="secondary"
                            variant="contained"
                            size="small"
                            className={classes.action}
                            onClick={() => {
                                if (user["verified"]) openCreateAppDialog();
                            }}
                            component="button"
                        >
                            <SvgIcon fontSize="small" className={classes.actionIcon}>
                                <PlusCircleIcon />
                            </SvgIcon>
                            New App
                        </Button>
                    </Tooltip>
                </Box>
            </Box>
            <Card>
                <CardContent>
                    <Box
                        display="flex"
                        alignItems="center"
                        mb={4}
                        mt={1}
                        justifyContent="space-between"
                    >
                        <Box display="flex" alignItems="center">
                            <Typography variant="body2" color="textSecondary">
                                Selected App:&nbsp;&nbsp;
                            </Typography>
                            <Typography variant="h5"> {selectedApp["name"]}</Typography>
                        </Box>
                    </Box>
                    <TextField
                        fullWidth
                        inputProps={{
                            disabled: true
                        }}
                        label="App ID"
                        value={selectedApp.app_sid}
                        variant="outlined"
                    />
                </CardContent>
            </Card>
            <SelectAppDialog
                open={open && user["verified"]}
                selectedApp={selectedApp}
                apps={apps}
                onClose={handleClose}
            />
            {createAppOpen && (
                <AddAccountAppDialog handleDialogClose={handleCreateAppDialogClose} />
            )}
        </>
    );
}

