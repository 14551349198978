// or get from process.env.REACT_APP_{var} to handle PROD and DEV environments
export const APP_VERSION = "2.0.0";
// export const API_BASE_URL = 'http://192.168.0.26:8080';

export const API_BASE_URL =
    process.env.REACT_APP_ENV == "prod"
        ? "https://api.ravenapp.dev"
        : "https://api.staging.ravenapp.dev";

export const ENABLE_REDUX_LOGGER = false;
